@icon-size: 26px;

.campus-menu-icon {
    position: relative;
    display: inline-flex;
    width: @icon-size;
    height: @icon-size;
    line-height: @icon-size;
    border-radius: @icon-size;
    align-items: center;
    justify-content: center;
    background: #808080;
    color: white;

    &.red {
        background-color: hsl(0deg, 70%, 60%);
    }

    &.orange {
        background-color: hsl(15deg, 70%, 60%);
    }

    &.green {
        background-color: hsl(120deg, 50%, 60%);
    }

    &.purple {
        background-color: hsl(264deg, 46%, 70%);
    }

    &.teal {
        background-color: hsl(150deg, 86%, 81%);
    }

    &.darkblue {
        background-color: hsl(212deg, 60%, 43%);
    }

}