.route-summary-narrow-container {
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 10px;
    justify-content: space-between;

    .metric-info-item {
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;

        &:first-child { margin-left: 10px; }
        &:last-child { margin-left: 5px; margin-right: 5px; }


        img {
            max-height: 16px;
            opacity: 0.85;
            margin-right: 4px;
            margin-left: 4px;
        }

        .metric-data {
            color: #444444;
            margin-left: 8px;
        }
    }
}