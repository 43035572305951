.expandable-booking-item {
    background: white;
    margin: 5px;
    position: relative;
    padding-inline-start: 20px;
    padding-block-start: 1px;
    padding-block-end: 1px;
    border-radius: 4px;
    border: 1px solid #E2E2E2;
    line-height: 24px;

    h3.title {
        margin-block-start: 0px !important;
        font-size: 12px !important;
    }

    .header-wrapper {
        margin: 0px;
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &.expandable {
            cursor: pointer;
        }

        .header-text-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }

    .availability-indicator-color {
        position: absolute;
        inset-inline-start: 0;
        width: 7px;
        inset-block-end: 16px;
        inset-block-start: 16px;
        border-start-end-radius: 10px;
        border-end-end-radius: 10px;
        border-start-start-radius: 0px;
        border-end-start-radius: 0px;
    }

    &.available .availability-indicator-color {
        background: #21EF48;
        background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.30) 0%, rgba(180, 180, 180, 0.07) 53%, rgba(0, 0, 0, 0.11) 100%);
    }

    &.unavailable .availability-indicator-color {
        background: #FF7000;
        background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.30) 0%, rgba(180, 180, 180, 0.07) 53%, rgba(0, 0, 0, 0.11) 100%);
    }


    .floor-building-name {
        color: #848484;
        font-weight: normal;
        font-size: 12px;
        margin-block-end: 0px;
    }

    .availability-status {
        color: #434546;
        font-size: 12px;
        margin-inline-start: 0px;
        margin-block-start: 2px;
        margin-block-end: 0px;
        display: inline-block;

        .calendar-available {
            width: 14px;
            height: 12px;
            margin-inline-end: 3px;
            align-items: center;
        }

        .calendar-unavailable {
            margin-block-end: 2px;
            margin-inline-end: 3px;
        }

        img {
            vertical-align: middle;
            width: 14px;
            height: 19px;
            margin-block-end: 5px;
        }
    }

    .expanded-status-icon {
        flex-grow: 0;
        transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        width: 20px;
        transform: rotate(90deg) scaleY(1.4);

        [dir="rtl"] & {
            transform: rotate(-270deg) scaleY(-1.4);
        }
    }

    .expand-icon {
        transform: rotate(180deg) scaleY(1.4);

        [dir="rtl"] & {
            transform: rotate(-360deg) scaleY(-1.4);
        }

    }


    .provider-logo-info-box {
        display: flex;
        justify-content: flex-end;
        margin-block-end: 48px;
        align-items: center;

        .provider-info-text {
            /* Not an important element, does not need to be super legible or meet WCAG requirements */
            font-size: 9px;
            color: #8a8a8a;
            margin-inline-end: 5px;
            text-align: right;
        }

        &.addmargin {
            margin-inline-end: 15px;
        }


    }

    .organizer-status {
        font-size: 12px;
        max-width: 230px;
        overflow: hidden;
        color: #434546;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-inline-start: 20px;
    }

}
