#sidebar-clickaway {
    z-index: 1019;
    display: none;

    &.active {
        display: block;
    }
}

@media (min-width:900px) {
    #sidebar-clickaway {
        display: none !important;
    }
}

.webapp-sidebar-background-translucent {
    background-color: rgba(0, 24, 46, 0.82);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1300; // Stay on top of map controls and sidebar
    display: none;
}