.responsive-card-container {
    position: absolute;
    inset-inline-start: 0px;
    inset-inline-end: 0px;
    margin: 0 auto;
    width: 340px;
    height: auto;
    border: 0px solid #bbb;
    background: white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    z-index: 999;
    border-radius: 6px;

    transition: inset-inline-start .5s; // Same duration as the sidebar anim in MazeMap.less.

    .titlebar {
        display: none;
    }

    &.mm-sidebar {
        margin: 0px;
        inset-inline-start: 0px;
        inset-block-start: 0px;
        height: 100%;
        width: 380px;

        .titlebar {
            display: block;
        }
    }

    &.full-width {
        width: 100%;
        border-start-end-radius: 18px;
        border-start-start-radius: 18px;
        overflow: hidden;
        margin: 0px;
        height: auto;
    }
}

.responsive-corner-card-container {
    inset-inline-start: 0px !important;
    margin: 10px;
    inset-block-start: 0px;
    inset-inline-end: initial;
    width: auto;
    min-width: initial;
    height: initial;
    bottom: initial;

    &.full-width {
        border-start-start-radius: 0;
        border-start-end-radius: 0;
    }
}

.responsive-bottom-card-container {
    inset-block-end: 25px;
    inset-block-start: initial;

    &.full-width {
        bottom: 0px;
    }
}
