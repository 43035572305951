.position-debug-ui {
    pre {
        width: 100%;
        height: auto;
        resize: none;
        border: none;
        background-color: #f1f1f1;
        color: #000;
        font-size: 11px;
        font-family: monospace !important;
        padding: 10px;
        margin: 10px 0px;
        outline: none;
        white-space: pre;
        word-break: break-all;
        word-wrap: break-word;
        user-select: text;
        line-height: 1.5;
        box-sizing: border-box;
    }

    .hidden-textarea {
        opacity: 0;
        height: 1px;
        min-height: 1px;
        max-height: 1px;
        overflow: hidden;
    }
}