.provider-login-status-wrapper {
    margin: 0px;
    padding: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    min-width: 220px;

    button {
        font-size: 14px !important;
    }

    .provider-logo {
        flex-grow: 0;
        min-width: 30px;
        align-items: center;
    }

    .provider-name {
        flex-grow: 1;
        padding-inline-start: 10px;
        color: #444;
        text-align: start;
    }
}
