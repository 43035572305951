.mmcm-location-map-control {
    .spin-animation {
        animation: mmcm-location-map-control-spin 2s infinite linear;
    }
}

@keyframes mmcm-location-map-control-spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}