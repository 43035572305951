

@focusring-width: 1px;
@focusring-color: #ff5200;
@focusring-color-white: #ff5200;

@focusring-radius: @focusring-width * 1.5;  // For outlines
@focusring-smoothness: @focusring-width * 5;  // For insets

*:focus {
    outline: none;
}
.aria-hidden{
    position: absolute;
    display: block;
    left: -10000px;
    top: -10000px;
    width: 0;
    height: 0;
    overflow: hidden;
}

/* The map focus element animation code */
@keyframes focus-flash-animation {
    0% {
        outline-color: rgba(255,0,0, 0);
        box-shadow: inset 0 0 5px 3px rgba(255,0,0, 0);
    }
    20% {
        outline-color: rgba(255,0,0, 1);
        box-shadow: inset 0 0 5px 3px rgba(255,0,0, 1);
    }
    100% {
        outline-color: rgba(255,0,0, 0);
        box-shadow: inset 0 0 5px 3px rgba(255,0,0, 0);
    }
}


.map-focus-element{
    display: none;
    position: absolute;
    top:   0px;
    left:  0px;
    width: 100%;
    height: 100%;

    outline-style: solid;
    outline-offset: 0px;
    outline-width: @focusring-width;
    outline-radius: @focusring-radius;
    outline-color: rgba(255,0,0, 1);;
    box-shadow: inset 0 0 5px 3px rgba(255,0,0,1);
    pointer-events: none;
}

body.keyboard-tabbing{
    :not(.normalfocus):focus::after,
    .normalfocus:focus{
        outline-style: solid;
        outline-offset: 0px;
        outline-color: @focusring-color;
        outline-width: @focusring-width;
        outline-radius: @focusring-radius;
        box-shadow: 0 0 5px 3px @focusring-color;
    }

    :not(.normalfocus):focus::after{
        content: '';
        position: absolute;
        top:   0px;
        left:  0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }

    .normalfocus:focus{
        z-index: 1;
    }

    .inset-focus:focus::after{
        box-shadow: inset 0 0 5px 3px @focusring-color;
    }

    .map-focus-element.flash{
        display: block;
        animation-name: focus-flash-animation;
        animation-duration: 1s;
        z-index: 9999;
    }

    .whitefocus:focus{
        outline-color: @focusring-color-white;
        box-shadow: 0 0 5px 3px @focusring-color-white;
    }

}


