@transition-duration: 0.5s;

.share-embed-map-ui {

  a,
  input {
    position: relative;
  }

  .share-element-styling {
    width: 100%;
    font-family: monospace;
    font-size: 12px;
    background: #F7F7F7;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #D4D4D4 !important;
  }

  .previewlink {
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin-top: 12px;
    color: #000;

    &:visited {
      color: #000;
    }

    &:hover {
      color: #000;
      text-decoration: underline
    }
  }
}