/* MazeMap GUI: CampusType List Container */

.mm-webapp-campus-types-list {
    padding: 0px;
    list-style-type: none;
    margin: 0px;
    /* making sure outline focus ring is visible */
    font-size: 14px;
    background: white;
    display: flex;
    flex-wrap: wrap;

    button {
        -webkit-appearance: none;
        background: none;
        border: 0;
        padding: 0;
    }

    .type-item {
        display: inline-flex;
        align-items: center;
        position: relative;
        padding: 10px 20px;

        font-size: 1em;
        font-weight: bold;


        border: 1px solid rgb(203, 203, 203);
        background-color: rgb(238, 238, 238);

        color: rgb(55, 55, 55);
        border-radius: 20px;
        margin: 4px;


        &:hover {
            background-color: rgb(245, 245, 245);
            font-weight: bold;
            cursor: pointer;
        }

        &.state-active {
            background-color: rgb(0, 173, 255);
            color: white;
            border-color: rgb(0, 115, 171);
        }

        .type-item-icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            border: 0;
            margin-inline-end: 3px;
            margin-inline-start: -6px;
        }
    }
}
