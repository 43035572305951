.responsive-app-layout{
    .scrollable-container{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
         /* Set this to prevent sidebar scroll to be hidden by z-fighting on Safari Desktop */
        z-index: 1;
    }
}