.mmcmn-zoom-control {
    .mmcmn-zoom-control-button:first-child {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .mmcmn-zoom-control-button:last-child {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }

    .mmcmn-zoom-control-image-container {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 31px;
    }
}