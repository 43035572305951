
.positioning-error-modal-content{
    background: rgba(42, 229, 81, 0.15);
    box-sizing: border-box;
    margin: 20px;
    padding: 20px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgba(255, 255, 255, 0.15);

    button {
        font-size: 18px;
    }

    .title {
        @media (max-width:414px)  {
        &{
            font-size: 25px;
        }
    }
    }

    .booking-info-box{
        font-size: 18px;
        width: auto;
        padding: 20px 30px;
        margin: 0px;
    }

    .error-image{
        display: block;
        margin-top: 12px;
    }

    .error-text{
        margin-bottom: 10px;
    }
    .error-subtext{
        font-size: 12px;
        margin-top: 0px;
        color: #5a5a5a;
    }
}