.roombooking-list-item {

    &.not-expandable {
        border: 0;
        margin: 0;
        margin-bottom: 10px;
    }

    .booking-error-message {
        display: flex;
        padding: 0 5px;
        align-items: center;

        .message {
            font-size: 13px;
            padding-left: 5px;
        }
    }

    .resource-booking-tabs {
        padding: 5px 15px;

        .tab-title {
            font-size: 12px;
            font-weight: bold;
        }

        .items {
            border-bottom: 1px solid #f3f3f3;
            align-self: flex-start;
        }

        .item {
            cursor: pointer;
            margin-right: 40px;
            &:last-child {
                margin-right: 20px;
            }
            border-bottom: 3px solid rgba(255, 255, 255, 0);

            &:not(.active):hover {
                border-bottom-color: lighten(#275773, 50%);
            }
            &.active {
                border-bottom-color: #275773;
            }

            h3.title {
                margin-bottom: 5px;
                text-align: center;
                margin-left: 0px;
                font-weight: bold;
            }
        }

        .contents {
            margin-top: 20px;
        }
    }
}
