@import 'common_modules/src/ui/map/mapcontrols.less';

.mmcmn-map-control-button {
    position: relative;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    font-size: 12px;
    padding: 0px;
    box-sizing: border-box;

    text-rendering: geometricPrecision;
    -webkit-appearance: none;
    -moz-appearance: none;

    cursor: pointer;

    background: none;
    color: inherit;
    border: 0;
    outline: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    background-color: white !important;

    .mmcmn-map-control-box-shadow();

    .mmcmn-map-control-border();

    &.hover {
        background-color: #e7e7e7 !important;
    }

}