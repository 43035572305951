.mmcmn-ui-modal-animate-opacity {
    opacity: 0;
    will-change: opacity;
    transition: opacity 200ms ease;
}

.mmcmn-ui-modal-animate-opacity.mmcmn-ui-modal-animate-in {
    opacity: 1;
}

.mmcmn-ui-modal-animate-opacity.mmcmn-ui-modal-animate-out {
    opacity: 0;
}


.mmcmn-ui-modal-animate-up {
    will-change: transform, opacity;
    transition-property: transform;
    transition-duration: 500ms;
    transition-timing-function: ease;
    transform: translateY(100%);
}

.mmcmn-ui-modal-animate-up.mmcmn-ui-modal-animate-in {
    transform: translateY(0);
}

.mmcmn-ui-modal-animate-up.mmcmn-ui-modal-animate-out {
    transform: translateY(100%);
}


.mmcmn-ui-modal-animate-fade-up {
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 500ms;
    transition-timing-function: ease;
    transform: translateY(100%);
    opacity: 0;
}

.mmcmn-ui-modal-animate-fade-up.mmcmn-ui-modal-animate-in {
    transform: translateY(0);
    opacity: 1;
}

.mmcmn-ui-modal-animate-fade-up.mmcmn-ui-modal-animate-out {
    transform: translateY(100%);
    opacity: 0;
}


.mmcmn-ui-modal-animate-scale-up {
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 500ms;
    transition-timing-function: ease;
    transform: scaleY(0) scaleX(0.9);
    opacity: 0;
}

.mmcmn-ui-modal-animate-scale-up.mmcmn-ui-modal-animate-in {
    transform: scaleY(1) scaleX(1);
    opacity: 1;
}

.mmcmn-ui-modal-animate-scale-up.mmcmn-ui-modal-animate-out {
    transform: scaleY(0) scaleX(0.9);
    opacity: 0;
}