.footer{

    border-top: 1px solid #00000029;
    overflow: auto;
    box-sizing: border-box;

    .title{
        font-weight: normal;
        font-size: 1em;
        box-sizing: content-box;
        font-weight: bold;
        padding: 0px;
        color: #222;
        margin-left: 20px;
        margin-top: 17px;
    }
}