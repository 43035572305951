.mmcmn-compass-control {
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    cursor: pointer;

    &.fade-in {
        animation-name: fade-in;
        animation-duration: .3s;
        animation-timing-function: ease-in-out;
    }

    &.fade-out {
        animation-name: fade-out;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
    }

}

/* fade in animation */
@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

/* fade out animation */
@keyframes fade-out {
    0% {
        opacity: 1
    }

    50% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}