.icon-title-tab {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 6px;
    padding-left: 4px;
    color: #616161;
    font-size: 16px;

    &:hover {
        color: #111111;
    }

    .tab-bottom-border {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        bottom: 0px;
        opacity: 0;
    }

    &:hover .tab-bottom-border {
        color: #111111;
        opacity: 0.3;
    }


    &.active {
        color: #000000;
    }

    &.active .tab-bottom-border {
        opacity: 1;
    }

    &.active:hover .tab-bottom-border {
        opacity: 1;
    }
}