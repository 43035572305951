.mm-wa-color-accented-card {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-sizing: border-box;
    margin: 0px;
    height: auto;
    padding-top: 9px;
    overflow: hidden;

    .mm-wa-color-accented-card-top-color {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 9px;
        background-color: #00a2ff;
    }

    ul {
        display: block;
        margin: 0px;
        margin-left: 37px;
        padding: 0px;
        margin-bottom: 0px;
        font-size: 13px;

        li {
            display: block;
            margin: 0px;
            padding: 0px;
        }
    }
}
