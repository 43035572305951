.mazemap-container {

    .mapboxgl-canvas-container {
        canvas {
            /* For some weird reason, the canvas gets pushed up 26.5px
               (the height of the directions and results views, and of the marker's margin-top) */
            top: 0;
        }
    }

    .mapboxgl-popup {
        z-index: 50;
    }


    /* Some custom mazebox styling overrides */

    /* bigger zoom-buttons */
    .mazemap-control-zlevel-bar.custom-zlevel-bar button {
        width: 35px !important;
        height: 35px !important;
        font-size: 16px;
        font-weight: bold;
        border-radius: 3px;
    }

    .mapboxgl-ctrl.mapboxgl-ctrl-group {

        button {
            width: 35px !important;
            height: 35px !important;
        }

    }

    .mapboxgl-map .mapboxgl-ctrl.mapboxgl-ctrl-group {
        box-shadow: 0 0 0 1px rgb(13, 88, 117);
    }

}