.mmcmn-ui-global-modal-wrapper {
    z-index: 9999;
    position: absolute;
    background: none;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    inset-block-start: 0px;
    inset-block-end: 0px;
    inset-inline-start: 0px;
    inset-inline-end: 0px;
    overflow: hidden;
}

.mmcmn-ui-global-modal-wrapper.center-content {
    align-items: center;
    justify-content: center;
}

.mmcmn-ui-global-modal-wrapper.top-content {
    align-items: stretch;
    justify-content: flex-start;
}

.mmcmn-ui-global-modal-wrapper.top-content .mm-app-modal-content {
    margin: 20px auto;
    max-width: 600px;
    width: calc(100% - 40px);
}

.mmcmn-ui-modal-background {
    z-index: 1;
    position: absolute;
    background: rgba(29, 25, 55, 0.61);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    inset-block-start: 0px;
    inset-block-end: 0px;
    inset-inline-start: 0px;
    inset-inline-end: 0px;
    overflow: hidden;
}

.mmcmn-ui-global-modal-wrapper .mm-app-modal-content {
    z-index: 1;
    background: none;
    width: auto;
    height: auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
}

.mmcmn-ui-global-modal-wrapper .no-cover {
    max-width: 90%;
    max-height: 90%;
}

.mmcmn-ui-global-modal-wrapper .mm-app-modal-content-offset {
    z-index: 1;
    background: none;
    width: auto;
    max-width: auto;
    max-height: auto;
    height: auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
}

