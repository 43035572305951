.mmcmn-select-styling-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;

    select {
        /* These styles are copied from frontend/common_modules/src/ui/Button/OutlinedButton/outlinedbutton.less */
        position: relative;
        font-family: 'Open Sans', sans-serif !important;
        font-weight: bold;
        font-size: 12px;
        padding-block: 7px;
        padding-inline: 13px;
        box-sizing: border-box;

        text-rendering: geometricPrecision;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        cursor: pointer;

        background: none;
        color: inherit;
        border: 0;
        outline: 0;
        border-radius: 100px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .mmcmn-select-styled-arrow-wrapper {
        display: block;
        position: absolute;
        inset-inline-end: 17px;
        width: 10px;
        height: 10px;
        z-index: 1;
        pointer-events: none;
    }
}
