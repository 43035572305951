.info-modal-content {
    background: rgba(42, 229, 81, 0.15);
    box-sizing: border-box;
    margin: 20px;
    padding: 20px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgba(255, 255, 255, 0.15);

    button {
        font-size: 18px;
    }

    @media (max-width:414px) {
        & .title {
            font-size: 25px;
        }
    }

    .modal-image-wrapper {
        display: block;
        margin-top: 12px;
    }

    .subtitle {
        margin-bottom: 10px;
    }

    .subtext {
        font-size: 12px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: #5a5a5a;
    }
}