.book-form-fields-container {
    display: flex;
    margin-left: 0px;

    .book-form-container {

        form-field {
            margin: 10px;
        }

        form-field:first-child {
            margin-left: 0px;
            margin-right: 0px;
        }

        form-field input {
            border-radius: 100px;
            border: 1px solid black;
            background: rgb(255, 253, 253);
            font-size: 12px;
            color: inherit;
            flex: 1 1 0%;
            min-height: 34px;
            font-weight: bold;
            line-height: 12px;
            padding: 7px 13px;
        }

        td {
            vertical-align: middle;
            padding: 5px;
            padding-left: 0px;
        }

        input {
            height: 30px;
            font-size: 13px;
            min-width: 130px;
            max-width: 195px;
        }

        label {
            display: block;
            font-size: 13px;
            margin-right: 10px;
        }

        .date-label-tag {
            font-size: 13px;
            font-weight: bold;
            color: #275773;
        }
    }

    .form-icon {
        display: block;
    }

    .datepicker-offset-left {
        left: -50px;
    }
}