.mmcmn-ui-corner-close-button {

    position: absolute;
    z-index: 1;
    inset-inline-start: auto;
    inset-inline-end: 0px;
    inset-block-start: 0px;
    inset-block-end: 0px;
    width: auto;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {

        /* Remove default webkit button appearance*/
        border: 0;
        background: none;
        -webkit-appearance: none;

        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin: 0px;
        inset-inline-end: 0px;
        font-size: 0px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 0px;
    }

    button:hover {
        color: #00608e;
    }

    &.fab {
        background: white;
        border-radius: 100%;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.28);
    }

}
