.mm-ui{
    font-size: 1em;
    font-family: 'Open Sans',"Geneva",Helvetica,Arial,sans-serif;
    text-rendering: geometricPrecision;
    -webkit-touch-callout:none;
    user-select:none;

    * {
        -webkit-tap-highlight-color: transparent;
    }

    input, textarea, select, button{
        font-family: 'Open Sans',"Geneva",Helvetica,Arial,sans-serif !important;
        text-rendering: geometricPrecision;
    }
}
.mm-ui h2{
    font-size: 16px;
    color: #595959; /* WCAG AAA compliant  */
    color: #757575; /* WCAG AA compliant  */
    margin-inline-start: 32px;
    margin-block-end: 4px;
    margin-block-start: 22px;
    font-weight: normal;
}


.mm-ui .error-message{
    margin: 20px;
    font-size: 13px;
}

.mm-ui .ui-message{
    margin: 30px 20px;
    display: block;
    text-align: center;
}

.mm-ui hr {
    position: relative;
    border: 0;
    display: block;
    height: 1px;
    min-height: 1px;
    background: rgb(234, 234, 234);
    margin: 18px 0px;
}

.mm-ui .text-caps{ text-transform: uppercase; }

/* Generic UI BUttons */
.mm-ui button{
    cursor: pointer;
}

.mm-ui .btn-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;

    &.text-small {
        font-size: 10px;
    }
}

.no-btn-style{
    border: 0;
    background: none;
    -webkit-appearance: none;
}

.mm-ui-cta-btn{
    position: relative;
    display: inline-block;
    border: 0px;
    background: rgb(0, 173, 255);
    color: white;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    min-height: 30px;

    &.flex {
        display: flex;
        flex-direction: row;

        &.center {
            justify-content: center;
        }
    }

    &.flat {
        background: none;
        color: rgb(0, 173, 255);

        &.border{
            border: 1px solid #EFEFEF;
        }
    }

    &.smallround {
        border-radius: 25px;
        font-size: 12px;
        text-transform: none;
        padding: 4px 16px;
        min-height: 25px;
    }

    &.margin {
        margin-inline-start: 30px;
        margin-block-start: 15px;
        margin-block-end: 10px;
        margin-inline-end: 30px;
    }

    &.darkblue {
        background: rgb(40, 87, 115);
        color: white;
        @at-root &:hover{
            background: rgb(60, 132, 175) !important;
        }
    }

    &.green {
        background: rgb(45, 200, 71);
        color: white;
        @at-root &:hover{
            background: rgb(40, 175, 62) !important;
        }
    }

    .icon {
        &.margin-right {
            margin-inline-end: 6px;
        }
    }

    &.gradient {
        background-image: linear-gradient(-180deg, rgba(255,255,255,0.30) 0%, rgba(180,180,180,0.07) 53%, rgba(0,0,0,0.11) 100%);
    }
}

.uppercase{
    text-transform: uppercase;
}

.poi-info-box,
.poi-details-page {
    button.mmcmn-button-filledbutton {
        :lang(zh),
        :lang(zh-CN),
        :lang(zh-Hans),
        :lang(zh-Hant) {
            // rules to handle text orientation in buttons
            width: max-content;
            word-break: keep-all;
            white-space: nowrap;
            writing-mode: horizontal-tb;
            text-orientation: upright;
        }
    }
}
