.ui-header-button-group {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    inset-block-start: 0px;
    inset-block-end: 0px;
    width: auto;

    &.position-left {
        inset-inline-start: 0px;
        align-items: flex-start;
        padding-inline-start: 6px;
    }

    &.position-right {
        inset-inline-end: 0px;
        align-items: flex-end;
        padding-inline-end: 6px;
    }

    &.position-relative {
        position: relative;
        align-items: flex-start;
        padding-inline-start: 6px;
    }
}

.ui-header-button {

    position: relative;
    width: 60px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button{

        /* Remove default webkit button appearance*/
        border: 0;
        background: none;
        -webkit-appearance: none;

        display: block;
        color: inherit;
        margin: 0px;
        inset-inline-end: 0px;
        font-size: 0px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 0px;

        &:hover{
            color: #00608e;
        }
    }

}
