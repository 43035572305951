.mm-webapp-components-chips-container {
    display: inline-flex;
    flex-wrap: wrap;

    // Add spacing between the middle items
    .mm-webapp-components-chip:not(:last-child) {
        margin-inline-end: 5px;
    }

    .mm-webapp-components-chip {
        margin-block-start: 2px;
        margin-block-end: 2px;
    }
}

.mm-webapp-components-chip {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 14px;
    border-radius: 5px;
    padding-block: 3px;
    padding-inline: 5px;
    font-size: 12px;
    background: #f3f3f3;
    border: 1px solid #e5e5e5;
}
