.mm-wa-language-selection {
    .image-buttons-list{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 10px;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)); /* https://css-tricks.com/intrinsically-responsive-css-grid-with-minmax-and-min/ */
        justify-items: left;

        .listitem{
            position: relative;
            padding: 5px;
            box-sizing: border-box;
            font-size: 13px;
            margin: 5px 2px;
            text-align: left;
            border: 1px solid white;
            background: none;
            cursor: pointer;
            white-space: nowrap;

            &:hover{
                border-color: rgb(187, 187, 187);
            }

            &.active{
                border: 1px solid rgb(0, 173, 255);
                border-radius: 4px;
            }
        }
        .listitem .list-item-image{
            vertical-align: middle;
            width: 22px;
            height: auto;
            margin-right: 5px;
        }

    }
}