.openinnewtab {
    background: 0;
    bottom: 5px;
    right: 5px;
    position: absolute;
    padding: 0;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 12px;

    border: 0;

    a,
    a:visited {
        color: #0078A8;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a:hover {
        text-decoration: underline;
    }
}

.openinnewtab.boxed {
    background: white;
    bottom: 3px;
    padding: 5px 10px;

    border: 1px #dadada solid;
    border-top: 0px solid rgb(221, 221, 221);
    box-shadow: inset 0px 1px 0px rgb(226, 226, 226);
}


/* ----------- Extra Small views ----------- */
@media screen and (max-width: 250px) {

    .responsive-hide-for-xs {
        display: none;
    }
}