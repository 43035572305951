.mm-wa-accessibility-selection {
    .betabadge {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        inset-inline-end: 0px;
        margin-block: -5px;
        margin-inline:  16px;
        margin-inline-end: 0px;
        padding-block: 5px;
        padding-inline: 10px;
        background-color: #0e5875;
        height: 10px;
        vertical-align: middle;
        border-radius: 80px;
        color: #fff;
        font-size: 11px;
        text-transform: uppercase;
    }
}
