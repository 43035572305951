.loadingOverlay{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: auto;
    height: auto;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.09);
    color: rgb(107, 107, 107);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    h1{
        font-size: 1.5em;
        background: rgba(255, 255, 255, 1);
        padding: 10px 20px;
        border-radius: 34px;
    }
}