.mm-ui.ui-options-container{
    position: relative;
    margin: 0px;
    padding-block: 5px;
    padding-inline: 0px 5px;
    background: rgb(248, 248, 248);

    .options-collapsable-toggle{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-inline-start: 2px;
        padding-inline-end: 2px;
        min-height: 20px;
        align-items: center;

        .options-header {
            margin-inline-start: 9px;
            font-size: 12px;
            color: rgb(117, 117, 117);
        }

        .options-item {
            font-size: 10px;
        }

        .btn-options-toggle {
            color: #005A7D;
            font-weight: bold;
        }

        .highlight{
            // color: red;
            margin-inline-start: 4px;
            font-weight: bold;
            position: relative;
            width: auto;
        }
    }

    .options-item-container {
        margin-block-start: 10px;
        margin-block-end: 10px;
    }

    .options-items{
        height: auto;
        min-height: 32px;
        display: flex;
        justify-content: flex-start;
        padding: 0px 10px;
        align-items: center;
        flex-direction: row;

        &:first-child{
            margin-block-start: 15px;
        }

        .options-item {
            margin-block-start: 10px;
            margin: 5px;
        }
    }

    .options-item{
        min-height: 20px;
        font-size: 12px;
        color: #005A7D;
        position: relative;


        &.extra-margin-inline-start {
            margin-inline-start: 20px;
        }
        &.description-label{
            font-weight: bold;
            margin-inline-end: 5px;
            line-height: 20px;
        }

        label, button{
            display: inline-flex;
            align-items: flex-start;
            vertical-align: middle;
            justify-content: center;
            line-height: 20px;
            cursor: pointer;
        }

        &.style-1{
            font-size: 12px;
            background: rgb(248, 248, 248);
            color: rgb(25, 25, 25);
            padding: 5px 10px;
            margin: 8px 5px;
            border-radius: 14px;
            border: 1px solid rgb(212, 212, 212);
        }

        input{ margin-inline-end: 7px; margin-inline-start: 0px; }

        .btn-content-wrapper{
            display: flex;
            color: #005A7D;
        }
    }
}
