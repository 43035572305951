.sidebar{

    position: relative;
    background: #ffffff;
    height: 100%;
    min-width: 320px;
    width: 320px;
    border-right: 1px solid #00000029;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .sidebar-content{
        z-index: 1;
        background: inherit;
        flex-grow: 1;

        overflow-y: scroll;

        &.no-scroll{
            overflow-y: hidden;
        }
    }
}