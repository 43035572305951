.bookable-slots-title {
    font-size: 12px;
    font-weight: bold;
    margin-top: 5px;
    display: block;
}

.show-more-slots-button {
    margin: 0 auto;
    margin-top: 12px;
    display: block;
    left: -4px; /* Visually looks more in the center */
}