.responsive-app-layout{
    .layout-header{

        z-index: 2;

        .title{
            font-weight: normal;
            font-size: 1em;
            box-sizing: content-box;
            font-weight: bold;
            padding: 0px;
            margin-left: 20px;
        }

        &.center{
            justify-content: center !important;

            .title{
                margin-left: 0px;
            }
        }
    }
}