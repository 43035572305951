@sidebar-animation-duration: .5s;
@maze-header-left-offset: 52px;

body {
    position: absolute;
    inset-block-start: 0px;
    inset-block-end: 0px;
    inset-inline-start: 0px;
    inset-inline-end: 0px;
    overflow: hidden;
    padding: 0;
    margin: 0;

    font-size: 1em;
    font-family: "droid_sansregular", Geneva, Helvetica, Arial, sans-serif;
    text-rendering: geometricPrecision;
    -webkit-touch-callout: none;
    user-select: none;

    line-height: 1.5em;
}

* {
    -webkit-tap-highlight-color: transparent;
}

input,
textarea,
select,
button {
    font-family: "droid_sansregular", Geneva, Helvetica, Arial, sans-serif !important;
    text-rendering: geometricPrecision;
    /* Necessary for iOS 15 to prevent default color blue on button text */
    /* https://developer.apple.com/forums/thread/690529 */
    color: black;
}

.mazemap-container {

    position: absolute;
    inset-inline-start: 0px;
    inset-inline-end: 0px;
    inset-block-start: 0px;
    inset-block-end: 0px;
    margin: 0px;
    padding: 0px;
    overflow: hidden;

    &.embed {
        border: 0px;
    }

    &.default-border {
        border: 1px #dadada solid;
    }

    &.newtab-bottom-offset {
        bottom: 27px;
    }


    .map {
        border: 0px;

        touch-action: none;

        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;

        z-index: 0;

        .mapboxgl-ctrl-top-left,
        .mapboxgl-ctrl-bottom-left {
            transition: left @sidebar-animation-duration;

            [dir="rtl"] & {
                transition: none;
            }
        }

        .mapboxgl-ctrl-top-right,
        .mapboxgl-ctrl-bottom-right {

            transition: none;
            [dir="rtl"] & {
                transition: right @sidebar-animation-duration;
            }


        }
    }

    .maze-header {
        transition: inset-inline-start @sidebar-animation-duration;
        inset-inline-start: @maze-header-left-offset;
    }

    // Sidebar status: forcefully opened/shown.
    @media (min-width:900px) {

        &.menu-open,
        &.add-sidebar-space {

            & .map .mapboxgl-ctrl-top-left,
            & .map .mapboxgl-ctrl-bottom-left {
                /* TODO: Find a way to avoid this global important override */
                /* This will probably be solved with the useMapMargin hook when
                the sidebar is made into a better react component (WIP planned change) */
                [dir="rtl"] & {
                    left: 0 !important
                }

                left: 320px !important;
            }

            & .map .mapboxgl-ctrl-top-right,
            & .map .mapboxgl-ctrl-bottom-right {
                /* TODO: Find a way to avoid this global important override */
                /* This will probably be solved with the useMapMargin hook when
                the sidebar is made into a better react component (WIP planned change) */
                [dir="rtl"] & {
                    right: 320px !important
                }

                right: 0 !important;
            }

            & .maze-header {
                /* TODO: Find a way to avoid this global important override */
                inset-inline-start: 320px !important;
            }

            & .responsive-card-container {
                /* TODO: Find a way to avoid this global important override */
                inset-inline-start: 320px !important;
            }

        }
    }

}

#skiptoscreenreader a {
    position: absolute;
    top: -70px;
    left: 0px;
    height: 60px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    max-width: 360px;
    text-align: center;
    color: white;
    font-size: 1.3em;
    font-family: droid_sansbold;
    line-height: 60px;
    border-right: 1px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgb(255, 255, 255);
    border-bottom-right-radius: 8px;
    background: rgba(0, 0, 0, 0);
    -webkit-transition: top .3s ease-out, background .6s linear;
    transition: top .3s ease-out, background .6s linear;
    z-index: 9999;
}

#skiptoscreenreader a:focus {
    position: absolute;
    left: 0px;
    top: 0px;
    background: #FF006A;
    outline: 0;
    -webkit-transition: top .1s ease-in, background .2s linear;
    transition: top .1s ease-in, background .2s linear;
}

/*
 * This is here because the Wave extension for chrome is annoyingly positioned on
 * top of the campus menu of the webapp.
 */
#wave_sidebar_container {
    left: unset !important;
    right: 0 !important;
}

/* Contrast mode specific color definitions */
@color: #054c67;
@contrast-cl-0: black;
/* very dark blue */
@contrast-cl-1: #001E29;
/* slightly lighter dark blue */
@contrast-cl-2: #023244;
/* dark, but can see it is blue */
@contrast-cl-3: #054c67;
@contrast-cl-9: white;

@anti-color: white;

.contrast-mode {

    .contrast-bg-3 {
        background: @contrast-cl-3 !important;
    }

    .contrast-bg-9 {
        background: @contrast-cl-9 !important;
    }

    .contrast-fg-1 {
        color: @contrast-cl-1 !important;
    }

    .contrast-fg-3 {
        color: @contrast-cl-3 !important;
    }

    .contrast-fg-9 {
        color: @contrast-cl-9 !important;
    }

    .contrast-fg-1-all * {
        color: @contrast-cl-1 !important;
    }

    .contrast-fg-3-all * {
        color: @contrast-cl-3 !important;
    }

    .contrast-fg-3-all .aria-selectable[aria-selected="true"] {
        color: @contrast-cl-9 !important;
        background: @contrast-cl-3 !important;

        * {
            color: @contrast-cl-9 !important;
        }
    }

    .contrast-border-3 {
        border-color: @contrast-cl-3 !important;
    }

    .contrast-bshadow {
        box-shadow: 0 0px 0px 2px #054c67 !important;
    }

    .contrast-btn-bg-3,
    .contrast-btn-bg-3:hover {
        background: @contrast-cl-3 !important;
        color: @contrast-cl-9 !important;
        border-color: @contrast-cl-3 !important;
    }

    .contrast-btn-bg-9,
    .contrast-btn-bg-9:hover {
        background: @anti-color !important;
        color: @color !important;
    }

    .contrast-color-fg {
        color: @color !important;

        em {
            color: @color !important
        }

        &.aria-selectable[aria-selected="true"] {
            background: @color !important;
            color: @anti-color !important;

            .contrast-color-fg,
            em {
                color: @anti-color !important;
            }
        }
    }

    .contrast-svg-fill-fg {
        fill: @color !important;
    }

    a.contrast-link {
        color: @color !important;
        text-decoration: underline !important;
    }

    .contrast-placeholder::-webkit-input-placeholder {
        color: red !important;
    }

    .contrast-placeholder::-moz-placeholder {
        color: red !important;
    }

    .contrast-placeholder::--moz-placeholder {
        color: red !important;
    }

    .contrast-placeholder::-ms-input-placeholder {
        color: red !important;
    }

    .search-field input#search-input-field {
        border: 1px solid @color;
    }

    .mazemap-control-zlevel-bar .z-label.active {
        background: @color !important;
        color: @anti-color;
    }

    .mapboxgl-ctrl-group {
        box-shadow: 0 0 0 2px @contrast-cl-1 !important;
    }
}

.rtl-icon-transform{
    [dir="rtl"] & {
        transform: scaleX(-1);
    }
}

