.mmcmn-button-filledbutton {
    position: relative;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    font-size: 12px;
    padding: 7px 13px;
    box-sizing: border-box;

    text-rendering: geometricPrecision;
    -webkit-appearance: none;
    -moz-appearance: none;

    cursor: pointer;

    background: none;
    color: inherit;
    border: 0;
    outline: 0;
    border-radius: 100px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}