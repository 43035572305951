.mmcm-modal-content-modal-card {
    // Fallback for browsers not supporting dvh units
    // Value found by trial and error. Just need to subtract enough
    // to account for browser footer and header in Safari and Chrome
    max-height: calc(100vh - 140px);
    // For modern browsers supporting dvh units we need to subtract less
    max-height: calc(100dvh - 40px);
    position: relative;
    box-sizing: border-box;
    overflow: auto !important;
}