.step-visualization-bar {
    display: flex;
    margin: 0px 0px;
    width: 10px;
    justify-content: center;
    z-index: 1;
    padding-inline-start: 30px;

    .color-bar {
        position: absolute;

        width: 6px;
        background: #1FAFFC;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        inset-block-end: -13px;
        inset-block-start: 13px;

        &.TRANSIT {
            background: #66DC7A;
        }

        &.DRIVE {
            background: #6134C4;
        }

        &.BICYCLE {
            background: #6134C4;
        }
    }


    .node {
        position: absolute;
        inset-block-start: 13px;
        width: 10px;
        height: 10px;
        background: white;
        border: 1px solid black;
        border-radius: 100%;
        box-sizing: border-box;

        &.start {
            inset-block-start: 0px;
        }

        &.point {
            background: white;
        }

        &.end {
            inset-block-end: 0px;
        }

        .icon-container {
            position: absolute;
            inset-inline-start: -32px;
            inset-block-start: -10px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
}
