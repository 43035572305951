

.mapboxgl-popup {

    .mapboxgl-popup-content {
/*         margin: 7px 20px; */
        margin: 0px;
        margin-top: 0px;
        width: 190px;
        padding: 1px;
        text-align: center;
        border-radius: 0px;
    }

/*     Mapbox-gl popup tips use the old "border-top" technique, not the "transform: rotate(45deg)" technique */
/*     This means that drop shadows are going to be a problem. */
/*    .mapboxgl-popup-content-wrapper, .mapboxgl-popup-tip {
        background: white;
        outline: none;
        box-shadow: 0 3px 10px rgba(0,0,0,0.2);
    }*/

    div.maze-popup-row {
        position: relative;
        display: block;
        min-height: 50px;
        border-bottom: 1px solid #e9e9e9;
        background-color: white;

        /* Prevent lines from appearing on top of the first button in the pop-up. */
        &:last-child {
            border-bottom: none;
        }

        >a,
        >div {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 50px;
            height: 50px;
            font-size: 13px;
            min-width: 190px;
        }

        > div {
            width: 190px;
        }

        .info-msg{
            padding: 10px 20px;
            box-sizing: border-box;
        }

        &.maze-popup-title > div > div {
            display:block;
            height: auto;
            min-height: 0;
            margin-bottom: 2px;
            margin-top: 10px;
            color: rgb(43, 43, 43);

            &.floor {
                font-size: 11px;
                font-weight: bold;
                margin-bottom: 12px;
                margin-top: 2px;
                color: rgb(6, 51, 68);
            }
        }

        &[aria-selected="true"] {
            background-color: rgb(219, 249, 255);
        }

        > a {
            text-decoration: none;
            font-size: 12px;
            color: rgb(22, 131, 182);
            cursor: pointer;
            width: 100%;

            &.red {
                color: #f46464;
                &:hover {
                    background-color: rgb(255, 245, 245);
                }
            }

            i {
                margin-right: 1em;
            }

        }
    }

}

