.welcome-modal-content {
    background: rgba(42, 229, 81, 0.15);
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.15);
    width: 500px;
    max-width: 100%;
    min-width: 275px;
    height: auto;
    z-index: 0;

    p {
        margin-top: 8px;
        margin-bottom: 8px;

        @media (max-height:400px) {
            & {
                margin-top: 2px;
                margin-bottom: 2px;
            }
        }
    }

    button {
        font-size: 18px;
    }

    .modal-top-image-wrapper {
        background-image: linear-gradient(0deg, rgba(75, 79, 105, 0.31) 0%, rgba(216, 216, 216, 0.00) 100%), url('../../../images/webapp-welcome-cover-image.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: relative;
        width: 100%;
        min-height: 100px;
        height: 200px;
        max-height: 20vh;
        box-shadow: 0px 2px 8px 0px #00000099;

        @media (max-height:600px) {
            & {
                max-height: 100px;
            }
        }

        @media (max-height:400px) {
            & {
                display: none;
            }
        }
    }

    .mazemap-logo-wrapper {
        position: relative;
        top: -50px;
        margin-bottom: -50px;
        width: 100px;
        min-width: 100px;
        height: 100px;
        min-height: 100px;
        border-radius: 200px;
        overflow: hidden;
        box-shadow: 0px 2px 15px 10px #00000017;
        background: white;
        margin: 0 auto;
        display: flex;
        align-self: center;
        justify-content: center;

        @media (max-height:600px) {
            & {
                height: 50px;
                width: 50px;
                min-width: 50px;
                min-height: 50px;
                top: -25px;
            }
        }

        @media (max-height:400px) {
            & {
                height: 25px;
                width: 25px;
                min-width: 25px;
                min-height: 25px;
                top: 6px;
                margin-bottom: 5px;
            }
        }
    }

    .mazemap-logo-text {
        margin-top: -30px;
        width: 175px;

        @media (max-height:600px) {
            & {
                margin-top: -15px;
                width: 135px;
            }
        }

        @media (max-height:400px) {
            & {
                display: none;
            }
        }
    }

    .user-start-text {
        font-size: 14px;
        margin-top: 50px;
        color: #434343;

        @media (max-height:400px) {
            & {
                margin-top: 10px;
            }
        }

    }

    .or-word-seperator {
        font-style: italic;
        font-size: 14px;
        color: #434343;
    }

    .skip-step-button {
        margin-top: 20px;
        text-decoration: underline;
        font-weight: normal;
        margin-bottom: 20px;

        @media (max-height:400px) {
            & {
                margin-top: 0px;
            }
        }
    }
}