.mmwa-navigation-hero {
    background: #003C5B;
    font-family: 'Open Sans', sans-serif !important;
    color: white;
    /* cap off at 30px but able to scale down on small screen */
    font-size: min(7vw, 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .mmwa-navigation-hero-grid-container {
        min-width: 320px;
        width: 100%;
        max-width: 600px;
        font-weight: bold;
        padding: 38px;
        padding-left: 26px;
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 1em;
        line-height: 1.5;

        @media screen and (min-width: 800px) {
            & {
                /* On wide screens, auto width will make the content centered in the top hero */
                width: auto;
            }
        }
    }

    .mmwa-navigation-maneuver-icon-wrapper {
        min-width: 2em;
        flex: 0;
    }

    .mmwa-navigation-hero-text-rows-container {
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
    }

    .mmwa-navigation-hero-primary-text-wrapper {
        font-size: 1em;
        white-space: break-spaces;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .mmwa-navigation-hero-secondary-text-wrapper {
        font-size: 0.75em;
        white-space: break-spaces;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}