@import 'common_modules/src/ui/map/mapcontrols.less';

.mmcmn-floor-bar-vertical-floor-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2em;
    min-width: 2em;
    height: 2em;
    min-height: 2em;
    /* outline: 1px solid red; */
    font-size: 39%;
    border-radius: 2em;
    font-weight: bold;
    margin-top: .25em;
    margin-bottom: .25em;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border: 0;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    span.floor-name-label {
        color: #494949;
        white-space: nowrap;
    }

    &:hover {
        background: #F5F5F5 !important;
    }

    &:disabled {
        opacity: 0.2;
    }
}

.mmcmn-floor-bar-vertical-floor-item-active {
    background: currentColor;

    span.floor-name-label {
        font-size: 1em;
        color: white !important;
    }

    &:hover {
        background: currentColor !important;
    }
}