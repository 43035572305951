@import 'common_modules/src/ui/map/mapcontrols.less';

.mmcmn-vertical-floor-bar-outer-container {
    position: relative;
    display: flex;
    font-size: 16px;
    width: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', 'Geneva', Helvetica, Arial, sans-serif;
    font-size: 40px;

    .label-container {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 2.1em;
        height: 2.1em;
        min-width: 4em;
        width: auto;
        line-height: 1em;
        font-size: 25%;
        font-weight: bold;
        margin-bottom: 1em;
        color: #545454;
        user-select: none;
        margin: 1em -1em;
        padding: 0px 3px;
    }

    .label-container span {
        font-size: 1em;
        font-weight: bold;
        color: #545454;
    }

    .vertical-floor-bar-container {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1.01em;
        font-size: 1em;
        background: white;
        box-sizing: border-box;
        border: 1px solid #D2D2D2;
        border-radius: 4px;
        user-select: none;
        overflow: hidden;

        .mmcmn-map-control-box-shadow();
        .mmcmn-map-control-border();
    }

    .floor-items-container {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: normal;
        box-sizing: border-box;

        &.scrollable {
            overflow-y: scroll;
            /* Safari on iOS version <= 15.3 does not support scroll-behavior */
            scroll-behavior: smooth;
            overflow: hidden;
            max-height: 100px;
        }

    }

    .ios15OverflowScrollingFix .floor-items-container {
        // See javascript function needsiOS15OverflowScrollingFix() in common_modules
        overflow-y: auto !important;
    }

    .scroll-arrow-button-container {
        &.hidden {
            max-height: 0em;
        }

        display: flex;
        max-height: 2em;
        transition: max-height .4s ease-in-out;
        overflow: hidden;
        width: 100%;
        z-index: 1;
        background: none;
        align-items: center;
        justify-content: center;

        &.bottom-shadow {
            box-shadow: 0px 0px 0.5em -0.1em rgb(0 0 0 / 35%), 0px 0.1em 0.1em 0px white;
        }

        &.top-shadow {
            box-shadow: 0px -0.1em 0.5em -0.1em rgb(0 0 0 / 35%), 0px -0.1em 0.1em 0px white;

        }
    }

}