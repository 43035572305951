.mm-cmn-typography-style {
    font-family: 'Open Sans', 'Geneva', Helvetica, Arial, sans-serif;
    color: #333;
}

.mm-cmn-typography-style.headline4 {
    font-weight: 700;
    font-size: 17px;
}

/* Optimized towork as a subtitle under headline 5 */
.mm-cmn-typography-style.subtitle4 {
    font-weight: 400;
    font-size: 14px;
}

.mm-cmn-typography-style.headline5 {
    font-weight: 600;
    font-size: 14px;
}

/* Optimized towork as a subtitle under headline 5 */
.mm-cmn-typography-style.subtitle5 {
    font-weight: 500;
    font-size: 14px;
}

.mm-cmn-typography-style.headline6 {
    font-size: 12px;
}

/* Optimized towork as a subtitle under headline 6 */
.mm-cmn-typography-style.subtitle6 {
    font-weight: 500;
    font-size: 12px;
}

.mm-cmn-typography-style.textbody {
    font-size: 12px;
    font-weight: normal;
    white-space: pre-wrap;
}

.mm-cmn-typography-style.texterror {
    font-size: 12px;
    font-weight: normal;
    white-space: pre-wrap;
    color: #E20555;
}