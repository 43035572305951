.responsive-app-layout {

    &.layout-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        background: inherit;
        z-index: 1;
    }

    &.wide-mode {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        background: inherit;
        flex-direction: row;
    }

    &.mobile-mode {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        background: inherit;
        flex-direction: column;
    }
}