.mm-wa-app-header-tools-button {
    position: relative;
    pointer-events: all;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    box-sizing: border-box;
    padding: 7px 15px;
    margin-inline-start: 0px;
    margin-inline-end: 10px;
    cursor: pointer;
    white-space: nowrap;
    min-height: 31px;

    &:first-of-type {
        margin-inline-start: 10px;
    }
    &:hover {
        background: #f3f3f3;
    }
}
