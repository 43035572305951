.mm-poi-details-page-container {
    background: white !important;

    .poi-sidebar{
        width: 340px;
        background: inherit;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
        z-index: 1;
        border-inline-end: 0;
    }

    .poi-header{
        position: relative;
        flex-grow: 0;
        background: #00adff;
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: rgb(0, 162, 255);
        box-shadow: 0px 1px 4px #424040;
        min-height: 60px;
        color: white;

        h2{
            color: white;
        }
    }


}
