.mm-wa-app-header-tools-wrapper {
    position: relative;
    width: 100%; /* mobile first sizing */
    margin-inline-start: 0px;
    z-index: 1008;
    height: auto;
    border: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    pointer-events: none;
    grid-template-areas:
        "search-header-placeholder"
        "tools-content";


    @media only screen and (min-width: 655px) {
        & {
            width: 600px;
            max-width: 100%;
            margin: 0 calc(50% - 300px);
        }
    }
}

.search-header-placeholder {
    grid-area: search-header-placeholder;
    pointer-events: none;
    height: 48px;
    width: 100%;

    @media only screen and (min-width: 655px) {
        & {
            height: 58px;
        }
    }
}

.tools-content {
    grid-area: tools-content;
    padding-block-start: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    padding-block-end: 30px; /* makes box-shadow of child components not clip due to overflow */
    display: flex;
    pointer-events: none;
    overflow: hidden;
    box-sizing: border-box;

    @media only screen and (min-width: 655px) {
        & {
            padding-inline-start: 20px;
        }
    }
}
