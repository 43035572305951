.mm-ui .positioning-control-button{
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 26px;
    border: 0px solid red;
    display: flex;

    button{
        -webkit-appearance: none;
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
    }
}