@width: 250px;
@row-height: 60px;
@row-height-small: 40px;
@button-width: 50px;
@list-font-size: 14px;
@list-font-size-small: 16px;

@separator-color: #F4F9FD;

@button-color: #00a2ff;

#campus-list {

    width: @width;

    &:focus {
        outline: none;
    }

    #campus-menu-title {
        position: relative;
        font-weight: bold;
        cursor: default;
        line-height: @row-height;
        border-block-end: 1px solid @separator-color;

        .root-title {
            margin: auto;
            max-width: 65%;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    ul {
        color: black;
        inset-inline-start: 0;
        inset-inline-end: 0;
        overflow-y: auto;
    }

    ul {
        display: block;
        margin: 0;
        padding: 0;
        min-width: @width;
        width: 100%;

        li {
            list-style-type: none;
            min-width: @width - 16px; // Because scrollbars :-S
            line-height: 26px;
            border-block-end: 1px solid @separator-color;
            cursor: pointer;
            text-align: left;

            >div,
            >a {
                position: relative;
                display: flex;
                align-items: center;
                line-height: @row-height;
                padding-inline-end: @button-width + 10px;
                padding-inline-start: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-height: @row-height;
                width: inherit;
                font-size: @list-font-size;
            }

            .icon-container {
                min-width: 50px;
                min-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 0;
            }

            .truncate-long-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:active,
            &[aria-selected="true"] {
                background: #0d5875;
                color: white;
            }

            @media (max-height: 500px) {

                >div,
                >a {
                    line-height: @row-height-small;
                    padding-inline-start: 0;
                    max-height: @row-height-small;
                    font-size: @list-font-size-small;
                }
            }

        }
    }

    position: relative;
    height: 20em;

    .backbutton {
        width: @button-width;
        font-size: list-font-size-small;
        background-color: none;
        text-transform: uppercase;
        color: #444444;
        text-align: center;
        position: absolute;
        inset-block-start: calc(@row-height / 4);
        inset-inline-start: 5px;
        line-height: calc(@row-height / 2);
        cursor: pointer;
        transition: background .1s linear;
        white-space: nowrap;
        display: inline-block;
        appearance: none;
        border: 0;
        background: none;

        i {
            margin-inline-end: 10px;
        }
    }

    .expand {
        width: @button-width;
        font-size: @list-font-size;
        background-color: none;
        color: greyscale(@button-color);
        text-align: center;
        position: absolute;
        inset-inline-end: 5px;
        line-height: calc(@row-height / 2);
        cursor: pointer;
        transition: background .1s linear;
        pointer-events: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    background: white;
    overflow: hidden;
}
