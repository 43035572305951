
#toast-container {
    position: fixed;
    z-index: 9999999;
    top: 7px;
    left: 7px;
    right: 7px;
    pointer-events: none;
}

.toast {
    pointer-events: all;
    background: white;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    padding: 15px 40px 15px 30px;
    width: 100%;
    max-width: 660px;
    box-sizing: border-box;
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    overflow: hidden;
    position: relative;

    opacity: 1;
    transform: translate(0%, 0%);
    margin: 0 auto;
    margin-top: 10px;
}
.toast:first-of-type {
    margin-top: 0px !important;
}