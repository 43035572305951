.ui-corner-close-button {

    position: absolute;
    z-index: 1;
    inset-inline-end: 0px;
    inset-block-start: 0px;
    inset-block-end: 0px;
    width: 60px;
    height: 59px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {

        /* Remove default webkit button appearance*/
        border: 0;
        background: none;
        -webkit-appearance: none;

        display: block;
        color: #fff;
        margin: 0px;
        inset-inline-end: 0px;
        font-size: 0px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        padding: 0px;

        &:hover {
            color: #00608e;
        }
    }

}
