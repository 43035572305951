.qrcode-ui {
    border: 0px solid red;
    background: white;
    display: flex;
    width: 100%;
    height: auto;
    min-height: 300px;
    align-items: start;
    justify-content: center;

    .actionlink {
        text-decoration: none;
        display: inline-block;
        font-size: 12px;
        color: #000;

        &:visited {
            color: #000;
        }

        &:hover {
            color: #000;
            text-decoration: underline
        }

        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;

        svg {
            margin-inline: 5px;
        }

        height: 24px;
        line-height: 24px;
    }

    .qr-code-action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-block-start: 12px;
    }

    .qr-code-preview {
        display: block;
        width: 256px;
    }

    .qrcode-custom-options {
        margin-inline-end: 50px;


        input {
            margin-block-start: 5px;
            margin-inline-end: 5px;
        }

        label {
            margin-block-end: 5px;
            display: inline-block;
            position: relative;
        }
    }
}
