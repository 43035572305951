.mm-resource-booking-container{

    .resource-booking-header-card {
        box-shadow: -5px 5px 12px #e0e0e0;
    }

    .resource-booking-sidebar{
        width: 340px;
        background: inherit;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
        z-index: 1;
        border-inline-end: 0;

        .scrollable-container {
            padding-block-end: 50px;
        }
    }

    hr {
        position: relative;
        border: 0;
        display: block;
        height: 1px;
        min-height: 1px;
        background: rgb(234, 234, 234);
        margin-block: 18px;
        margin-inline: 0px;

        &.no-margin{
            margin: 0px;
        }
    }

    .resource-booking-title-header{
        position: relative;
        flex-grow: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #275773;
        box-shadow: 0px 1px 4px #424040;
        min-height: 60px;
        color: white;

        .header-icon {
            margin-inline-end: 10px;
            margin-inline-start: -20px; /* to make it not impact the centering of header title */
        }

        h2{
            color: white;
        }
    }

    .mobile-header-wrapper {
        .list-meta-info {
            background: #f5f5f5;
            margin: 0px;
            padding-block: 5px;
            padding-inline: 10px;
            box-shadow: -5px 5px 12px #e0e0e0;
        }
    }

    .card {
        margin: 10px;
        padding: 10px;
        z-index: 1; /* to make box-shadow work over siblings */
        position: relative;

        &.mobile-header-card {
            margin: 0px;
        }
    }

    .list-meta-info {
        font-size: 11px;
        margin-inline-start: 20px;
        margin-block-end: 10px;
    }
}
