.mm-wa-app-dropdown-overlay-wrapper {
    position: absolute;
    top: 0px;
    border: 0px solid black;
    background: white;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: auto;
    max-width: 100vw;
    height: auto;
    pointer-events: all;

    animation-timing-function: cubic-bezier(.55,1.73,.46,1.01);
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;

    &.animate-in {
        animation-name: mm-wa-app-dropdown-overlay-animate-in;
    }
    &.animate-out {
        animation-name: mm-wa-app-dropdown-overlay-animate-out;
        animation-timing-function: cubic-bezier(.47,-0.36,.61,.99);
    }
}

@keyframes mm-wa-app-dropdown-overlay-animate-in {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes mm-wa-app-dropdown-overlay-animate-out {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

#mm-app-dropdown-overlays-global-container {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2000;
    pointer-events: none;
}