.mm-wa-resource-booking-menu {
    p {
        margin-block: 1px;
        margin-inline: 20px; /* Left for LTR, Right for RTL */
        font-size: 14px;
        display: flex;
    }

    button {
        margin-block-start: 0px; /* Top */
        margin-block-end: 10px;  /* Bottom */
        margin-inline-start: 0px; /* Left for LTR, Right for RTL */
        margin-inline-end: 0px;  /* Right for LTR, Left for RTL */
        white-space: nowrap;
    }

}
