button.google-signin-button-image {
    position: relative;
    background-color: white;
    background-image: url(./btn_google_signin_light_normal_web@2x.png);
    background-repeat: no-repeat;
    background-position: 0, 0;
    width: 191px;
    height: 46px;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    overflow: visible;
    display: inline-block;
    vertical-align: middle;
    background-size: 191px 46px;
    border-radius: 4px;
    justify-self: center;
    align-self: center;

    &:focus,
    &:hover {
        background-image: url(./btn_google_signin_light_focus_web@2x.png);
    }

    &:active {
        background-image: url(./btn_google_signin_light_pressed_web@2x.png);
    }

    &:disabled {
        opacity: 0.5;
    }
}