@transition-duration: 0.5s;

.CampusSelect {
    text-align: center;
    /*     position: absolute; */
    /*     height: auto; */
    height: 100%;
    bottom: 0;

    width: auto;
    min-width: 250px;

    html.lt-ie9 & {
        border: 1px solid #aeaeae;
    }

    //padding: 10px;
    //padding-right: 25px;

    z-index: 1001;
    /* map bars have z-index 1000 */

    top: 0px;
    // bottom: 15px;

    &.right {
        /*         right: -500px; */

        padding-left: 0;

        transition: right @transition-duration, width @transition-duration;
    }

    &.top-bar {
        top: -100px;
        transition: height @transition-duration, top @transition-duration;

        hr.arrow:after {
            margin-left: 33px;
        }

        hr.arrow:before {
            margin-left: 31px;
        }
    }

    & h1 {
        color: #ffffff;
        font-weight: normal;
        font-size: 18px;
        width: 100%;
        box-sizing: content-box;
        font-weight: bold;
        margin: 0px;
        padding: 0px;

        i {
            margin-left: -45px;
        }
    }

    .expanded {
        display: block
    }

    ;

    .list-bottom-shadow {
        display: none;
    }

    .list-bottom-shadow.hidden {
        display: none;
    }

    & .menu.expanded {
        margin-inline-start: auto;
        margin-inline-end: auto;
        position: relative;
        z-index: 1002;
        box-shadow: 0px 1px 4px #424040;
        min-height: 50px;

        .header {
            min-height: 60px;
            line-height: 60px;
            display: flex;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    #campus-list {
        height: auto;
        /*         top:0; */
        inset-block-end: 0px;
        position: absolute;
        width: 100%;

        div {
            inset-block-start: 0;
        }

        /*
            Some weird webkit bugs on iOS devices
            More info here
            http://stackoverflow.com/questions/26176288/webkit-overflow-scrolling-touch-breaks-in-apples-ios8
            http://stackoverflow.com/questions/9801687/using-webkit-overflow-scrolling-touch-hides-content-while-scrolling-dragging
            http://patrickmuff.ch/blog/2014/10/01/how-we-fixed-the-webkit-overflow-scrolling-touch-bug-on-ios/
        */
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        /*MAKES OVERFLOWN OBJECTS HAVE INERTIA SCROLLING*/
        -webkit-transform: translateZ(0px);
        /*Seems to prevent the scroll-bug where the content disappears*/


        top: 65px;

    }

    &.top-bar {

        height: 80px;
        width: 100%;
        bottom: auto;

        box-shadow: 0 3px 0px rgba(0, 0, 0, 0.35);


        html.lt-ie9 & {
            border: 0px;
            border-bottom: 3px solid #aeaeae;
        }

        & .expanded {
            display: none
        }

        ;

        &.right {
            right: -100%;
            transition: height @transition-duration;
        }

        &.list {
            height: 100%;

            html.lt-ie9 & {
                border-bottom: 0px;
            }
        }

        .campus-list {
            top: 89px;
        }

    }

    .settings-button {
        position: absolute;
        inset-inline-start: 0px;
        width: 50px;
        height: 50px;
        border: none;
        cursor: pointer;
    }

}
