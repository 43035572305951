.ui-button {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    padding: 7px 13px;
    box-sizing: border-box;

    text-rendering: geometricPrecision;
    -webkit-appearance: none;
    -moz-appearance: none;

    cursor: pointer;

    background: none;
    color: inherit;
    border: 0;
    outline: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 4px;
}

.ui-button:hover {
    background: #be0e31;
}

.ui-button:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline: -moz-focus-ring-color auto 5px;
}

.ui-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
