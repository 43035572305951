.closest-available-bookable-resource-modal-card {
  position: relative;
  max-width: calc(100vw - 40px);
  width: 600px;
  min-width: 300px;
  margin: auto;
  inset-block-start: 8px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: auto;
  height: auto;
  border-radius: 0;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    inset-block-start: 0;
    max-width: calc(100vw - 40px);
  }

  .header {
    display: flex;
    flex-direction: row;

    .header-icon {
      width: 50px;

      .calendar-icon {
        margin-block-start: 14px;
        margin-inline-start: 16px;
      }
    }

    .header-content {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      margin-block-start: 12px;

      .header-title {
        font-size: 22px;
        text-align: center;
      }

      .header-subtitle {
        margin-block-start: 5px;
        font-size: 14px;
        text-align: center;

        .betabadge {
          margin-inline-start: 8px;
          font-size: 9px;
        }
      }
    }

    .header-close {
      width: 50px;

      .closebutton {
        width: 50px;
        height: 50px;
        line-height: 50px;
        color: #333;
        font-size: 14px;
        padding: 0;
        border: 0;
        background-color: transparent;
      }
    }
  }

  .separator {
    margin-top: 6px;

    hr {
      height: 1px;
      background-color: #ccc;
      border: none;

      margin: 8px 0 0 0;
      padding: 0;
    }
  }

  .content {
    flex: 1 0 auto;
    max-height: 270px;
    overflow-y: auto;

    .error {
      padding-top: 22px;
      font-size: 14px;
      text-align: center;
      padding-inline-end: 50px;
      padding-inline-start: 50px;
      padding-block-end: 14px;
    }

    .location-error {
      padding-block-start: 12px;
      font-size: 14px;
      text-align: center;
      padding-inline-end: 50px;
      padding-inline-start: 50px;
      padding-block-end: 14px;

      .location-error-button {
        margin-block-start: 7px;
        margin-inline-start: auto;
        margin-inline-end: auto;

        &:hover {
          background: #067def !important;
        }

        .location-button-icon {}

        .location-button-text {
          margin-inline-start: 8px;
          margin-block-start: 1px;
        }
      }
    }

    .result-loading-wrapper {
      padding-block-start: 10px;
      text-align: center;
      padding-block-end: 14px;

      .result-loading {
        height: 60px;
        width: 60px;
      }
    }

    .result {
      padding-block-start: 18px;
      height: 100%;
      cursor: pointer;
      padding-block-end: 14px;

      &:hover {
        background: #f7f7f7;
      }

      display: flex;
      flex-direction: row;

      .result-waypoint-icon-container {
        width: 30px;
        height: 25px;
        margin-inline-start: 16px;

        .result-waypoint-icon {
          width: 23px;
          height: 27px;
        }
      }

      .result-poi-content {
        flex: 1 0 0;
        margin-inline-start: 10px;

        display: flex;
        flex-direction: column;

        .result-title {
          font-size: 16px;
          font-weight: bold;
          color: #424445;

          max-width: 350px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media only screen and (max-width: 600px) {
            max-width: 160px;
          }
        }

        .result-location {
          font-size: 12px;
          color: #676464;

          max-width: 350px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media only screen and (max-width: 600px) {
            max-width: 170px;
          }
        }
      }

      .result-button-wrapper {

        .result-button {
          margin-block-start: 6px;
          margin-inline-end: 16px;
          font-size: 12px;
          text-transform: uppercase;
          padding-block: 9px;
          padding-inline:  8px;

          &:hover {
            background: rgb(40, 175, 62) !important;
          }
        }
      }
    }

    .bookable-type-item {
      display: flex;
      align-items: center;
      margin-block-start: 5px;
      padding-block: 18px;
      padding-inline:  18px;

      .find-closest-btn {
        display: inline-block;
        margin-inline-start: auto;
      }

      .bookable-type-name {
        text-transform: uppercase;
        padding-inline-start: 10px;
        color: #0e5875;
      }

      .bookable-type-icon {
        display: flex;
        justify-content: center;

        .bookable-type-img {
          width: 40px;
          justify-content: center;
        }
      }
    }
  }

}
