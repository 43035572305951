@font-face {
    font-family: 'droid_sansregular';
    src: url('DroidSans-webfont.eot');
    src: url('DroidSans-webfont.eot?#iefix') format('embedded-opentype'),
         url('DroidSans-webfont.woff') format('woff'),
         url('DroidSans-webfont.ttf') format('truetype'),
         url('DroidSans-webfont.svg#droid_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

