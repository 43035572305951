.mm-wa-app-dropdown-menu-card {
    position: relative;
    min-width: 240px;
    max-width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 16px;
        margin: 20px;
        margin-block-start: 12px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-inline-end: 60px; /* Accounting for an absolute-positioned close button */
    }
}
