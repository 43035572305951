.report-modal-content {
    background: rgba(42, 229, 81, 0.15);
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: rgba(255, 255, 255, 0.15);
    width: 100%;
    min-width: 275px;
    height: auto;
    z-index: 0;

    p {
        margin-top: 8px;
        margin-bottom: 8px;

        @media (max-height:400px) {
            & {
                margin-top: 2px;
                margin-bottom: 2px;
            }
        }
    }

    button {
        font-size: 18px;
    }

    label {
        cursor: pointer;
        text-align: left;
        position: relative;
    }
}