.mm-ui .route-instructions-component{
    display: block;
    margin-top: 20px;

    li .item-content{
        padding-left: 16px;
        display: inline-block;
        vertical-align: top;
        color: #161616;
        font-weight: normal;
    }
}