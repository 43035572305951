.booking-modal {
    flex-direction: column;

    .booking-info-box-container {
        background: rgba(42, 229, 81, 0.15);
        box-sizing: border-box;
        margin: 20px;
        padding: 20px;
        border-radius: 6px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(255, 255, 255, 0.15);

        .booking-status-icon {
            display: block;
            margin: 5px;
        }

        .booking-in-progress-loading-image {
            width: 50px;
            padding: 0px;
            margin-block: 20px;
            margin-inline: auto;
            display: block;
            margin-block-end: 10px;
            color: red;
        }

        .booking-message-title {
            margin-block-start: 0px;
            margin-block-end: 5px;
            font-size: 28px;
            line-height: 1.2em;
        }

        .booking-message-text {
            margin-block-start: 5px;
            margin-block-end: 5px;
        }

        .booking-message-subtext {
            font-size: 12px;
            margin-block-start: 5px;
            margin-block-end: 5px;
            color: #5a5a5a;
        }

        .time-slot-element {
            /* some overrides to make it bigger. But should maybe not be here */
            padding: 10px;

            .info-block .time-span-row {
                font-size: 16px !important;
            }

            .booking-reservation-buttons {
                justify-content: center;
            }

            .booking-reservation-link-text {
                font-size: 15px;
            }


        }
    }
}
