.mm-wa-more-apps-menu {
    display: flex;
    flex-direction: column;

    p {
        margin-block: 0px;
        margin-inline: 20px;
        font-size: 14px;
    }

    .no-btn-style {
        border: 0;
        background: none;
        -webkit-appearance: none;
    }

    .mm-application-link-wrapper {
        position: relative;
        margin: 0px;
        padding: 0px;
        margin-block: 10px;
        margin-inline: 20px;
        cursor: pointer;
        max-width: 300px;
        &:hover .mm-application-link-item{
            background: #f7f7f7;
        }
        &:first-of-type {
            margin-block-start: 20px;
        }
        &:last-of-type {
            margin-block-end: 20px;
        }
    }

    .mm-application-link-item {
        position: relative;
        width: auto;
        min-width: 200px;
        padding: 10px;
        padding-block-start: 10px;
        box-sizing: border-box;
        display: grid;
        border: 0;
        background: white;
        grid-template-columns: 50px 1fr;
        grid-template-rows: min-content min-content;
        gap: 0px 15px;
        grid-auto-flow: row;
        grid-template-areas:
            "icon   title"
            "icon   description";
        font-family: 'Open Sans', sans-serif !important;
        text-rendering: geometricPrecision;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-align: start;
        line-height: 1.8;
    }

    .icon {
        grid-area: icon;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title {
        grid-area: title;
        font-size: 14px;
        font-weight: bold;
        white-space: nowrap;
    }
    .description {
        grid-area: description;
        font-size: 12px;
        font-weight: normal;
    }
}
