@search-max-width: 600px;
@result-item-height: 62px;

.search-result {
    min-height: @result-item-height;
    height: auto;
    line-height: 14px;
    padding-block-start: 9px;
    padding-block-end: 9px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 60px auto;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    color: #6e6e6e;
    grid-template-areas:
        "icon-area result-area";


    border-block-start: 1px solid #f0f0f0;
    cursor: pointer;

    &:first-of-type {
        padding-block-start: 4px;
    }

    .icon-area {
        grid-area: icon-area;
        line-height: @result-item-height;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(0, 102, 154);
        position: relative;
        flex: 0;
        height: 33px;
        padding-block-start: 9px;
        padding-inline-start: 9px;
    }

    .result-area {
        grid-area: result-area;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
    }

    .search-result-names-wrapper {
        display: flex;
        flex-direction: column;

        .title-and-campus-wrapper {
            margin-block-start: 13px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        span.title {
            font-size: 14px;
            line-height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            flex-grow: 1;
        }

        span.alternative-names {
            font-size: 12px;
            line-height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-inline-end: 20px;

            em {
                font-family: droid_sansbold;
            }
        }

        span.campus-name {
            line-height: 16px;
            width: auto;
            flex-shrink: 0.8;
            min-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-inline-start: 20px;
            margin-inline-end: 20px;
            text-align: right;
        }
    }

    span.search-result-location {
        display: block;
        font-size: 12px;
        line-height: 12px;
        margin-inline-end: 20px;
        margin-inline-start: 0px;
        margin-block-start: 5px;
        margin-block-end: 5px;
        color: #737373;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .search-result-floor-name {
        display: inline-block;
        margin-block-end: 10px;
        line-height: 12px;
        font-size: 12px;
        margin-block-start: 5px;
    }

    .search-result-category-label {
        font-size: 12px;
        margin-block-start: 5px;
    }

    .search-result-outdoors-name {
        font-size: 12px;
        margin-block-start: 5px;
    }

    .search-result-building-name {
        font-size: 12px;
        margin-block-start: 5px;
    }

    &.search-page-layout-simplified {


        .result-area {
            grid-area: result-area;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            overflow: visible;
        }

        .title-and-campus-wrapper {
            overflow: hidden;
            max-width: min(500px, calc(100vw - 80px));
        }

        /* Overrides for simplified search layout */
        span.title {
            font-size: 16px;
            color: black;
        }

        span.campus-name {
            display: none;
        }

        .search-result-outdoors-name {
            font-size: 14px;
        }

        .search-result-floor-name {
            border: 1px solid #919191;
            font-size: 14px;
            line-height: 14px;
            padding: 6px 7px;
            border-radius: 30px;
            position: relative;
            inset-inline-start: -6px;
            margin-block-end: 13px;
        }

        .search-result-building-name {
            display: none;
        }

    }
}

/* &:focus .search-result[aria-selected="true"] { */
.search-result[aria-selected="true"] {
    background: #E4EFFD;
    color: #1E4B77;

    .search-result-icon {
        color: #1E4B77;
    }

    .search-result-location {
        color: #111;
    }
}

.search-result:active,
.search-result:focus {
    background: #DDE8F7;
}

@media only screen and (max-width : @search-max-width) {
    .search-result {
        grid-template-columns: 44px auto !important;
        padding-block-start: 0px;
        padding-block-end: 0px;

        .icon-area {
            padding-inline-start: 0px;
        }
    }
}
