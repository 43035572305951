.instant-book-now-slots {

    .intant-book-now-buttons-wrapper {
        display: grid;
        grid-auto-columns: 0.5fr;
        grid-template-areas: "a a";
        grid-gap: 10px;
    }

    .instant-bookable-slots-title {
        font-size: 12px;
        font-weight: bold;
        display: block;
        margin-bottom: 10px;
    }
}