
/* MazeMap GUI: Route Details Container */


.mm-ui.route-details-component{
    position: relative;
    background: rgb(255, 255, 255);

    h2.flex{
        display: flex;
        justify-content: space-between;
    }
    font-family: 'Open Sans',"Geneva",Helvetica,Arial,sans-serif;
    font-weight: normal;
    color: #161616;

    &.scrollable{
        flex-grow: 1;
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
    }

    .metric-info-item{
        display: flex;
        flex-shrink: 0;
        align-items: center;
    }
}

.mm-ui ol.steps-list, .mm-ui ul.steps-list{
    margin: 0px;
    padding: 0px;
    list-style-position: outside;
    margin-inline-start: 37px;
    margin-inline-end: 5px; /* making sure outline focus ring is visible */
    margin-block-end: 10px;
    font-size: 14px;
    position: relative;

    li{
        margin-inline-end: 25px;
        padding-block-end: 17px;
        padding-block-start: 17px;
        color: black;
        font-weight: 600;
    }
}

.mm-ui ul.steps-list{
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
