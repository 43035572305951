.mm-wa-mmauth-login-ui {
    p {
        margin: 20px;
        font-size: 12px;
        display: flex;
    }
}

.mm-outlook-login-button {
    display: flex;
    align-items: center;
    gap: 12px;
    background: #ffffff;
    color: #5e5e5e;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    width: 191px;
    height: 41px;
    margin: 0;
    justify-self: center;
    font-size: 15px;
    font-family: "Segoe UI Light", serif;

    &:hover {
        border: #b8d6f4 3px solid;
    }
}