@sidebar-animation-duration: .5s;

/* z-indexes */

@z-index-sidebar: 1020;
@z-index-sidebar-clickaway: 1019;

.mm-sidebar {
    touch-action: none;

    position: absolute;
    transition: transform @sidebar-animation-duration;
    transform: translateX(-100%);
    inset-inline-start: 0;
    inset-block-start: 0;
    inset-block-end: 0;
    z-index: @z-index-sidebar;  // On top of click-away (1019) which is on top of search/header (1010), which is on top of map (1000)
    overflow: hidden;
    box-shadow: none;
    background: #ffffff;
    width: 320px;
    max-width: 90%;

    [dir="ltr"] & {
        transform: translateX(-100%);
    }

    [dir="rtl"] & {
        transform: translateX(100%); /* RTL */
    }
}

.visible-position{
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
    transform: translateX(0); // LTR and RTL come to view

    [dir="ltr"] & {
        transform: translateX(0);
    }

    [dir="rtl"] & {
        transform: translateX(0); // Ensure RTL also animates to view
    }
}





