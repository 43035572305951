.mm-map-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    background: #fefefe;

    #map {
        display: block;
        flex-grow: 1;
        position: relative;
        top: initial;
        left: initial;
        right: initial;
        width: auto;
        height: auto;
    }
}