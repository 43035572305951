.route-description-container {
    margin-block-start: 10px;
}

.mm-ui .route-step-details-item {
    display: block;
    margin: 0px;
    margin-inline-start: 5px;
    margin-inline-end: 5px;

    button {
        display: block;
        width: 100%;
        color: #444444;
        padding-block: 10px;
        padding-inline: 8px;
        border-radius: 6px;
        position: relative;

        &:hover {
            background: rgb(249, 249, 249);
            cursor: pointer;
        }
    }

    .step-item-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: stretch;
        padding-inline-start: 5px;

        .step-item-title {
            /* Walking 345 m       3 min*/
            display: flex;
            flex-grow: 1;
            font-size: 12px;
            justify-content: space-between;

            .left {}

            .right {}
        }

        .step-information-container {
            flex-grow: 1;
        }

        .description {
            font-weight: bold;
            display: inline-flex;
        }

        .warning-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-inline-start: 10px;
        }

        .meters {
            font-weight: normal;
            margin-inline-start: 1em;
        }

        .duration {
            color: #757575;
        }

        .step-time-estimate {
            margin-inline-start: 10px;
            font-size: 14px;
        }
    }

    .step-information-container {
        display: block;
        text-align: start;
    }

    .step-detail-item-content {
        padding: 10px;
        flex-direction: column;
        padding-block-start: 10px;
        margin-block-start: 0px;
        align-items: flex-start;
        border-radius: 8px;
        margin-inline-start: 7px;
        margin-block-start: 4px;

        &.card {
            background: #F7F7F7;
            border: 1px solid #EBEBEB;
        }
    }

    .leave-arrive-info {
        margin-block-start: 15px;
        margin-block-end: 10px;

        .time {
            font-size: 1.2em;
            font-weight: bold;
        }

        .desc {
            font-weight: bold;
            font-size: 0.8em;
            margin-inline-start: 0.8em;
            text-transform: uppercase;
        }

        .name {
            font-size: 1em;
            display: block;
        }

    }

    .transit-cased-title {
        padding: 3px 6px;
        margin-inline-end: 0.5em;
        color: #272727;
    }
}
