// The search overlay uses some stuff from the modal dialogs

@search-max-width: 600px;
@header-height: 50px;
@search-scope-height: 44px;
@header-offset-height: 8px;

@result-item-height: 62px;

@background2: #F4F4F4;

@report-footer-height-small: 36px;
@report-footer-height-big: 78px;

@results-list-height-medium: 420px;

.modal-search.fullscreen {

    margin: auto;

    z-index: 1301; // Stay on top of Mapbox controls and on top of header and on top of the sidebar and on top of background-clickaways

    max-width: @search-max-width;

    position: absolute;
    top: 8px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: auto;
    text-rendering: auto;

    .search-empty-state-text {
        display: block;
        padding: 50px 40px;
        max-width: 300px;
        box-sizing: border-box;
        text-align: center;
        font-size: 12px;
        margin: 0 auto;
        color: #A2A2A2;
    }

    div.search-field {
        position: relative;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding: 0;
        border: 0;
        width: 100%;
        height: 100%;

        height: @header-height;

        div.search-input-container {
            /*             display: block; */
            position: absolute;
            height: @header-height;
            top: 0;
            inset-inline-start: 0;
            inset-inline-end: 50px; // Because of the width of the close-button

            span.search-icon {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;
                top: 0px;
                inset-inline-start: 0px;
                font-size: 14px;
                line-height: @header-height;
                height: @header-height;
                vertical-align: middle;
                color: #aaa;
                padding-inline-start: 12px;
                pointer-events: none;
                /* because this item is only for visual and should not capture mouse events */
            }

            input[type="search"] {
                display: block;
                position: absolute;
                width: 100%;
                height: @header-height;
                box-sizing: border-box;
                /* becuase input-fields are not playing nicely */
                font-size: 14px;
                line-height: 14px;
                /* not using line-height == header-height because of iOS/webkit input field cursor size */
                padding-inline-start: 44px;
                padding-inline-end: 15px;
                -webkit-appearance: none;
                /* because Apple */
                border: none;
                border-radius: 0px;
                border-start-start-radius: 4px;
            }
        }

    }



    div.search-scope {
        background: @background2;
        font-size: 11px;
        line-height: 23px;
        padding-top: 11px;
        padding-bottom: 10px;
        padding-inline-start: 20px;
        position: relative;
        inset-inline-start: 0;
        inset-inline-end: 0;

        .info-string {
            margin-inline-end: 20px;
            display: none;
        }

        em {
            font-style: normal;
            color: #0096DD;
        }

        .buttons-wrapper {
            position: relative;
            display: inline-block;
            margin-inline-start: -10px;
        }

        .button,
        button {
            position: relative;
            border: 0px solid rgb(203, 203, 203);
            background-color: none;
            font-style: normal;
            text-decoration: none;
            padding: 8px 15px;
            border-radius: 17px;
            margin: 0px 4px;
            cursor: default;
            font-size: 12px;
        }

        .button:hover,
        button:hover {
            background-color: #E9EDF2;
        }

        .button.active,
        button.active {
            border: 1px solid rgb(203, 203, 203);
            background-color: #EEEEEE;
        }

        .button.active:hover,
        button.active:hover {
            background-color: #EEEEEE;
        }

        .other-scopes {
            display: inline-block;
            margin-inline-start: 15px;
            margin-inline-end: 0.5em;

            button {
                color: #0096DD;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }

    .closebutton {
        cursor: pointer;
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        height: @header-height;
        width: @header-height;
        line-height: @header-height;
        font-size: 14px;
        color: #fff;
        text-align: center;
        background-color: inherit;
        border-start-end-radius: 4px;

        &:focus {
            box-shadow: inset 0px 0px 2px 1px #00a3ff;
        }
    }

    button {
        -webkit-appearance: none;
        background: none;
        border: 0;
        padding: 0;
    }

    .search-report-footer {
        position: relative;
        bottom: 0;
        height: @report-footer-height-small;
        line-height: @report-footer-height-small;
        inset-inline-start: 0;
        width: 100%;
        background-color: @background2;
        vertical-align: middle;
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        font-size: 11px;
        color: #8C8C8C;
        box-sizing: border-box;
        box-shadow: 0px 0px 7px -3px #000;
        overflow: visible;
        display: flex;
        align-items: center;
        justify-content: start;

        .report-search-problem-btn {
            position: relative;
            color: #222;
            text-decoration: none;
            margin-inline-start: 30px;
            height: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            outline-offset: -4px;

            &:focus {
                box-shadow: none
            }

            .success {
                color: #22B406;
            }
        }

        .small-only {
            display: none;
        }
        .search-report-icon {
            width: 50px;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            color: grey;
            padding-inline-start: 10px;
            padding-inline-end: 0px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.big {
            bottom: -@report-footer-height-big;
            height: @report-footer-height-big;
            line-height: @report-footer-height-big;
        }

        &.round-bottom {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .search-results {
        position: relative;
        inset-inline-start: 0;
        inset-inline-end: 0;


        /*         height:     @results-list-height; */
        overflow: auto;
        -webkit-overflow-scrolling: touch; // Y U NO STANDARD, SAFARI?
        background: rgb(250, 250, 250);
        margin: 0;
        padding: 0;
        color: #555;

        &.round-bottom {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        /*  add a min-height as a hack to
            prevent the content to become too short
            on mobile devices with keyboard up.
            Example is on Android where the actual
            body viewport will be affected by the keyboard,
            and we want the content to seem "scrollable" behind
            the keyboard, so we make it have a "substantial" min-height */
        min-height: 150px;

        /* Style for emphasized text (used to mark hits in text) */
        em {
            color: #111;
            font-style: normal;
            font-family: droid_sansbold;
        }

        .search-error {
            text-decoration: none;
            text-shadow: none;
            display: flex;
            overflow: hidden;
            padding: 0px;
            border-top: 1px solid rgb(240, 240, 240);
            vertical-align: middle;
            margin: 0px;
            min-height: @result-item-height;
            height: auto;
            line-height: @result-item-height;
            font-weight: normal;
            white-space: nowrap;
            position: relative;
            color: #6e6e6e;
            /* WCAG Contrast checked http://webaim.org/resources/contrastchecker/ */

            .search-error-icon {
                display: inline-flex;
                min-width: 56px;
                padding-inline-start: 4px;
                align-items: center;
                justify-content: center;
                height: 60px;
                text-align: center;
                color: rgb(0, 102, 154);
                font-size: 18px;
            }

            .search-error-text {
                display: inline-block;
                white-space: normal;
                line-height: 24px;
                margin-top: 18px;
                margin-inline-start: 0px;
            }

            span.search-error-view {
                display: block;
                font-size: 14px;
                /*                 line-height: 14px; */
            }

            &:first-child {
                padding-top: 4px;
            }
        }

        div.search-spinner {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-inline-start: 25px;
            margin-top: 30px;
            padding-bottom: 9px;

            .search-spinner-text {
                margin-inline-start: 15px;
                display: block;
                font-size: 14px;
            }

        }

    }

    // Medium screens
    @media only screen and (min-width: @search-max-width) {

        div.search-field div.search-input-container {

            span.search-icon {
                padding-inline-start: 24px;
            }

            input[type="search"] {
                padding-inline-start: 60px;
            }
        }

        .search-results {
            .search-error {
                padding-top: 9px;
                padding-bottom: 9px;

                &:first-child {
                    padding-top: 9px;
                }
            }

        }

        div.search-scope {
            font-size: 12px;
            padding-inline-start: 20px;

            .info-string {
                display: inline-block;
            }

        }

    }

    // Medium screens
    @media only screen and (max-width: @search-max-width) {
        .search-results .search-error .search-error-icon {
            width: 44px;
            padding-inline-start: 0px;
            margin-inline-start: 0px !important;
        }

    }

    .search-results.no-footer {
        @media only screen and (min-height: @results-list-height-medium + 150px) {
            max-height: @results-list-height-medium + 50px;
        }

        @media only screen and (max-height: @results-list-height-medium + 150px - 1px) {
            max-height: calc(100vh - 102px);
        }
    }

    .search-results.has-small-footer {
        @media only screen and (min-height: @results-list-height-medium + 115px + 21px + @report-footer-height-small) {
            max-height: @results-list-height-medium + 21px;
        }

        @media only screen and (max-height: @results-list-height-medium + 115px + 21px + @report-footer-height-small - 1px) {
            max-height: calc(100vh - 130px - 21px);
        }
    }

    // .search-results.has-big-footer {
    //     @media only screen and (min-height: @results-list-height-medium + 150px + @report-footer-height-big) {
    //         max-height: @results-list-height-medium + 8px ;
    //     }
    //     @media only screen and (max-height: @results-list-height-medium + 150px + @report-footer-height-big) {
    //         max-height: calc(100vh - -102px - 36px - 42px);
    //     }
    // }

    &.osk-height .search-results {

        /* Search bar + margin: 150px; on-screen keyboard: 288px; Total 438px */
        @media only screen and (min-height: @results-list-height-medium + 150px + 288px) {
            max-height: @results-list-height-medium;
        }

        @media only screen and (max-height: @results-list-height-medium + 150px + 288px -1px) {
            max-height: calc(100vh - 438px);
        }
    }

    &.search-page-layout-simplified {
        /* Overrides for the simplified search page layout */

        .search-scope {
            display: none;
        }
    }
}


/* Small screens */
/* This is the same behaviour as the header menu: stick to the top of the page when less than ~600px */

@media only screen and (max-width : 440px) {

    .modal-search .search-report-footer .report-search-problem-btn {
        margin-inline-start: 0px !important;
        padding-inline-start: 10px;
        padding-inline-end: 20px;
        float: inline-end;

        .success {
            margin-inline-end: 0px;
            width: 60px;
            display: inline-block;
        }

        .big-only {
            display: none;
        }

        .small-only {
            display: inline-block;
        }

        i {
            margin-inline-end: 0px;
            margin-inline-start: 0px;
        }

    }
}

@media only screen and (max-width : @search-max-width) {
    .modal-search {
        width: 100%;
        top: 0 !important;

        .search-results.no-footer {
            @media only screen and (min-height: @results-list-height-medium + 94px) {
                max-height: @results-list-height-medium + 94px;
            }

            @media only screen and (max-height: @results-list-height-medium + 94px -1px) {
                max-height: calc(100vh - 94px);
            }
        }

        .search-results.has-small-footer {
            @media only screen and (max-height: @results-list-height-medium + 115px + @report-footer-height-small - 1px) {
                max-height: calc(100vh - 130px);
            }
        }

        .search-report-footer {
            .search-report-icon {
                width: 44px !important;
                padding-inline-start: 0px !important;
            }

        }

        div.search-spinner {
            margin-inline-start: 12px !important;
            margin-top: 25px !important;

            .search-spinner-text {
                margin-inline-start: 12px !important;
            }

        }
    }
}

/* This should not really be a global style, but will be removed
when the search modal is refactored into a proper react component
*/
.search-modal-background-translucent {
    background-color: rgba(0, 24, 46, 0.82);
    position: fixed;
    top: 0;
    bottom: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    z-index: 1300; // Stay on top of map controls and sidebar
    display: none;
}

