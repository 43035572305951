.mm-wa-distance-units-selection {
    .image-buttons-list{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-block-start: 10px;
        margin-inline-start: 20px;
        margin-inline-end: 10px;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr)); /* https://css-tricks.com/intrinsically-responsive-css-grid-with-minmax-and-min/ */
        justify-items: start;

        .listitem{
            position: relative;
            padding: 5px;
            box-sizing: border-box;
            font-size: 13px;
            margin-block: 5px;
            margin-inline: 2px;
            text-align: start;
            border: 1px solid white;
            background: none;
            cursor: pointer;
            white-space: nowrap;

            &:hover{
                border-color: rgb(187, 187, 187);
            }

            &.active{
                border: 1px solid rgb(0, 173, 255);
                border-radius: 4px;
            }
        }

    }
}
