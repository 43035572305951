@import 'common_modules/src/ui/map/mapcontrols.less';

.mmcmn-floor-bar-scroll-arrow-button-container {
    &.hidden {
        max-height: 0em;
    }

    display: flex;
    max-height: 2em;
    transition: max-height .4s ease-in-out;
    overflow: hidden;
    width: 100%;
    z-index: 1;
    background: none;
    align-items: center;
    justify-content: center;

    &.bottom-shadow {
        box-shadow: 0px 0px 0.5em -0.1em rgb(0 0 0 / 35%), 0px 0.1em 0.1em 0px white;
    }

    &.top-shadow {
        box-shadow: 0px -0.1em 0.5em -0.1em rgb(0 0 0 / 35%), 0px -0.1em 0.1em 0px white;

    }
}