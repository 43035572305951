.mmcmn-button-textbutton {
    font-size: 12;
    position: relative;
    display: inline-flex;
    font-weight: bold;
    font-family: 'droid_sansbold', Geneva, Helvetica, Arial, sans-serif !important;
    text-rendering: auto;
    align-items: center;
    justify-content: center;
    border: 0;
    /* When hovering this will affect the shape of the background color */
    border-radius: 8px;
    /* Text buttons rarely wants to auto-fill the width of the container, so setting this to 0 */
    flex: 0;
    outline: none;
    min-height: 24px;
    line-height: 12px;
    padding: 0px 10px;
    cursor: pointer;
    white-space: nowrap;
}