.mm-directions-container {

    .directions-sidebar {
        width: 320px;
        background: inherit;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
        z-index: 1;
        border-right: 0;
    }

    hr {
        position: relative;
        border: 0;
        display: block;
        height: 1px;
        min-height: 1px;
        background: rgb(234, 234, 234);
        margin: 18px 0px;

        &.no-margin {
            margin: 0px;
        }
    }

    /* Some overrides due to specific context where component is placed */
    .ui-options-container {
        padding-inline-start: 43px !important;
        margin-block-start: -15px !important;
        padding-block-end: 8px !important;

        .options-item-container {
            padding-inline-start: 12px;
        }
    }


    .directions-title-header {
        position: relative;
        flex-grow: 0;
        background: #00adff;
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: rgb(0, 162, 255);
        box-shadow: 0px 1px 4px #424040;
        min-height: 60px;
        color: white;

        h2 {
            color: white;
        }
    }

    .map-control-area-top-center {
        position: absolute;
        inset-block-start: 0px;
        inset-inline-start: 50%;
        height: auto;
        width: auto;
        transform: translateX(-50%);
        /* to center-position an absolute-positioned item */
        z-index: 1;
        margin-block-start: 10px;
        align-items: center;
    }

    .directions-footer {
        display: flex;
        flex-direction: column;
    }

    .directions-ui-mode-details {

        &>div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            max-height: 260px;
        }
    }

    .route-info-card-content {
        padding-block: 5px;
        padding-inline: 20px;
    }

    .directions-navigation-card-content {
        padding-block: 5px;
        padding-inline: 20px;
    }

    .route-info-card-action-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 6px;

        .btn-svg-icon {
            height: 8px;
            margin-block-start: 3px;
            margin-inline-end: 5px;
            display: inline-block;
            vertical-align: top;
        }
    }

}
