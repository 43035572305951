@z-index-sidebar-btn-show: 1018;

@header-height: 50px;

@header-border-width: 1px;
@header-border: @header-border-width solid #404040;

@search-inset-width: 6px;

.maze-header {
    display: flex;

    margin: auto;

    z-index: 1010; // Stay on top of map controls and sidebar show button
    width: 600px;
    height: @header-height;

    position: absolute;
    inset-block-start: 8px;
    /*     left:300; */
    /* Redefined in MazeMap.less */
    inset-inline-end: 0;

    border-radius: 8px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

    button {
        position: relative;
        cursor: pointer;
        -webkit-appearance: none;
        background: none;
        border: 0;
        padding: 0;
    }

    .search-button-wrapper {
        display: flex;
        height: @header-height;
        margin: 0;
        vertical-align: top;
        flex-grow: 1;
    }

    .search-button {
        display: block;
        width: 100%;
        height: 100%;
        color: white;
        &.left-item {
            border-end-start-radius: 8px;
            border-start-start-radius: 8px;
        }

        .maze-header-search-inset {
            box-sizing: border-box;
            background: rgba(60, 123, 140, 0.84);
            border: #0E5875 @search-inset-width solid;
            box-shadow: inset -7px -4px 17px -4px rgba(202, 213, 230, 0.36);
            &.left-item {
                border-end-start-radius: 10px;
                border-start-start-radius: 10px;
            }

            font-size: 14px;
            font-family: droid_sansbold;

            line-height: @header-height - 2 * @search-inset-width;
            text-align: center;

            &.app-config-themed {
                background: white;
                color: #333;
                border-color: unset;
                border-radius: 10px;
            }
        }

    }

    .maze-header-menuitems {
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;

        &.right-item {
            border-end-end-radius: 8px;
            border-start-end-radius: 8px;
        }
        &.left-item {
            border-end-end-radius: 8px;
            border-start-start-radius: 8px;
        }

        &:not(.app-config-themed) {
            // Hack to make the background color work for the legacy styled header
            // Whenever we can remove the legacy styled header, this can be removed
            background: #00ADFF;
        }

        height: @header-height;
        line-height: @header-height;

        font-size: 14px;

        display: inline-block;
        padding: 0;
        margin: 0;
        cursor: pointer;

        .maze-header-button>i {
            font-size: 19px;
        }

        .maze-header-button .menu-item-badge {
            pointer-events: none;
            display: block;
            position: absolute;
            inset-block-start: 6px;
            inset-inline-end: 6px;
            background: #FF006A;
            color: rgb(255, 255, 255);
            border-radius: 100%;
            width: 18px;
            height: 18px;
            font-size: 11px;
            font-weight: bold;
            text-align: center;
            line-height: 18px;
            vertical-align: middle;
            box-shadow: 0px 0px 0px 1px white, 0px 1px 3px 1px rgb(101, 101, 101);
        }

        >button {
            width: @header-height;
            height: @header-height;

            display: inline-block;
            vertical-align: top;
            margin: 0;

            border-radius: 8px;

            text-align: center;

            i {
                font-size: 125%;
            }

            background: #00ADFF;
            color: #FDFDFD;

            &:hover,
            &.active {
                background: #FFFFFF;
                color: #00ADFF;
            }

        }

        /* Somewhat hacky overrides for when the header is styled via app config theme */
        /* TODO/NOTE: Only set up for dark-ish primary colors, with hardcoded white text/icon color */
        &.app-config-themed>button {
            background: inherit;
            color: white;
            border-radius: 8px;

            &:hover,
            &.active {
                background: rgba(255, 255, 255, 0.25);
                color: white;
            }
        }

    }
}


/* Small screens */
/* This is the same behaviour as the header menu: stick to the top of the page when less than ~600px */
@media only screen and (max-width : 655px) {
    .maze-header {
        border-radius: 0px;
        inset-block-start: 0;

        .search-button .maze-header-search-inset {
            border-start-start-radius: 0px;
            border-end-start-radius: 0px;
        }

        .maze-header-menuitems {
            border-end-end-radius: 0px;
            border-start-end-radius: 0px;
        }
    }

    .maze-header.app-config-themed .maze-header-search-inset {
        border-radius: 30px !important;
    }

}

.button-sidebar-show {
    display: block;
    position: absolute;
    z-index: @z-index-sidebar-btn-show;
    inset-inline-start: -2px;
    inset-block-start: 7px;
    width: 54px;
    height: 50px;
    cursor: pointer;

    text-align: center;
    text-decoration: none;
    font-size: 16px;
    line-height: 47px;

    border: 0;
    background-color: #0E5875;
    color: #fff;
    border-radius: 0px;
    border-start-end-radius: 8px;
    border-end-end-radius: 8px;

    transition-property: padding, background-color;
    transition-duration: .2s;
    transition-timing-function: linear;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

    :lang(zh),
    :lang(zh-CN),
    :lang(zh-Hans),
    :lang(zh-Hant) {
        // rules to handle text orientation in buttons
        width: max-content;
        word-break: keep-all;
        white-space: nowrap;
        writing-mode: horizontal-tb;
        text-orientation: upright;
    }

    .text {
        display: none;
    }

    &:hover {
        filter: brightness(120%);
    }

    @media (min-width:900px) {

        &,
        &:hover {
            width: auto;
            line-height: 47px;
            padding-inline-start: 15px;
            padding-inline-end: 25px;
        }

        & span.text {
            display: inline-block;
            margin-inline-start: 10px;
        }
    }

    @media (max-width:655px) {
        & {
            width: 54px;
            font-size: 20px;
            top: 0px;
            padding: 0px;
            border-start-end-radius: 0px;
            border-end-end-radius: 0px;
        }
    }
}
