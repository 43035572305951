.card {
    background: #FFFFFF;
    background-image: linear-gradient(-180deg, #FFFFFF 50%, #FAFAFA 100%);
    box-shadow: 0 3px 22px 0 rgba(0,0,0,0.37);
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.clickable {
    cursor: pointer;
    will-change: transform;
    transition: transform 0.05s linear;
}

.clickable:hover {
    transform: scale(1.02);
}