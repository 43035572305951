.mm-ui.route-mode-container{
    position: relative;
    margin: 0px;
    padding: 5px 10px 5px 10px;
    background: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    height: 44px;

    .mode-item{
        border-radius: 40px;
        position: relative;
        fill: #575757; /* for svg children inheriting fill color */
        min-height: 32px;
        font-size: 12px;
        color: #005A7D;
        width: 60px;
        padding: 5px 0px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;


        .selected{ display: none; }

        &:hover{
            background: rgb(241, 241, 241);
        }

        &.selected{
            background: #006691;

            .default{ display: none;}
            .selected{ display: inline-flex !important;}
        }

        .btn-content-wrapper{
            display: flex;
            color: #005A7D;
            position: absolute;
        }
    }


    &.small{
        height: 30px;
        padding: 2px 10px 2px 10px;

        button{
            min-height: 26px;
            height: 26px;
        }

        img{
            max-height: 16px;
        }
    }

}

