.bookable-slot-item {

    .buttons-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 10px;
        margin-bottom: 10px;

        :lang(zh),
        :lang(zh-CN),
        :lang(zh-Hans),
        :lang(zh-Hant) {
            // rules to handle text orientation in buttons
            width: max-content;
            word-break: keep-all;
            white-space: nowrap;
            writing-mode: horizontal-tb;
            text-orientation: upright;
        }
    }

    &.compact {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .buttons-wrapper {
            margin-bottom: 0px;
        }
    }
}
