
#KeyBoardWrapper{
    display: none;
    position: absolute;
    width: 100%;
    height: 308px;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    bottom: 0px;
}

#mmKeyboard{
    background-color:rgb(0, 150, 221);
    padding:0px; width:100%; height:288px;
    margin: 0px;
    display:block; border-top:0px solid #000;
    position:absolute; bottom:0px; left:0px;
      font-size:14px; 
      font-family: Arial, "Helvetica" ;
      font-weight:normal;
      z-index: 9999; 
        }
#mmKeyboard:after {content: ".";display: block;height: 0;clear: both;visibility: hidden;}

#mmKeyboard #keyboardHeader {position:relative;}
#mmKeyboard #keyboardHeader div {color:#fff; background-color:#7b8490;padding:3px; padding-left:15px; padding-right:15px; position:absolute; cursor:pointer; right:-10px; top:-40px;}
#mmKeyboard .closex {font-weight:bolder; color:#000;}

#mmKeyboard #keyboardCapitalLetter {display:none; text-align: center;}
#mmKeyboard #keyboardSmallLetter {display:block; text-align: center;}
#mmKeyboard #keyboardNumber {display:none; text-align: center;}
#mmKeyboard #keyboardSymbols {display:none; text-align: center;}

#mmKeyboard .button 
{
    vertical-align: middle;
    display: inline;
    display: inline-block;
    width:40px; height:50px;background-color:#fff; position:relative;
    /*float:left;*/
    margin-right:9px;margin-top:10px;
    cursor:pointer !important;
    font-size:1.9em;

    /*box shadow*/ -webkit-box-shadow: 0px 1px 3px #000000;-moz-box-shadow: 0px 1px 3px #000000;box-shadow: 0px 1px 3px #000000;
    /*box radius*/ -moz-border-radius: 5px;border-radius: 5px;
    /*gradient*/
    background: #ffffff; /* old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #e5e5e5 100%); /* firefox */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#e5e5e5)); /* webkit */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* ie */
}
#mmKeyboard .buttonDown {background:none;background-color: #c9cdd5; -webkit-box-shadow:none;-moz-box-shadow: none;box-shadow: none;
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9FA0A2', endColorstr='#c9cdd5',GradientType=0 ); /* ie */}
#mmKeyboard .button_a {margin-left:26px;}
#mmKeyboard .button_dash {margin-left:26px;}
#mmKeyboard .button_underscore {margin-left:26px;}
#mmKeyboard .button_enter {width:103px; font-size:1.3em; }
#mmKeyboard .button_del{font-size:1.3em;}
#mmKeyboard .button_delright{width: 100px; font-size:1.3em;}
#mmKeyboard .button_smallletter{font-size:1.3em;}
#mmKeyboard .button_smallletterleft{width:100px; font-size:1.3em; margin-left:0px;}
#mmKeyboard .button_capitalletterleft{font-size:1.3em;}
#mmKeyboard .button_capitalletterright{ width:129px; font-size:1.3em;}
#mmKeyboard .button_numberleft{width:100px; font-size:1.3em; margin-left:0px;}
#mmKeyboard .button_numberright{width:129px; font-size:1.3em;}
#mmKeyboard .button_space {width:300px;}
#mmKeyboard .button_symbolsleft{width:172px; font-size:1.3em; margin-left:26px;}
#mmKeyboard .button_symbolsright{width:129px; font-size:1.3em;}

#mmKeyboard br {

}

#mmKeyboard .key {width:100%; height:100%; text-align:center; padding-top:12px; text-shadow: 0px 1px 1px #ffffff; cursor: pointer;}
#mmKeyboard .key_del {padding-top:18px;}
#mmKeyboard .key_enter {padding-top:18px;}
#mmKeyboard .key_smallletter {padding-top:18px;}
#mmKeyboard .key_capitalletterleft {padding-top:18px;}
#mmKeyboard .key_capitalletterright {padding-top:18px;}
#mmKeyboard .key_number {padding-top:18px;}
#mmKeyboard .key_symbols {padding-top:18px;}


/* content {width:700px; height:100px; border:1px solid #000; margin:10px; font-family:Arial Unicode MS; font-weight:normal;} */

/*input[type=text] {border:1px solid #000; width:300px; height:30px; color:#999; font-family:Verdana; font-size:25px; padding:5px; padding-left:8px; margin-bottom:10px;}*/

