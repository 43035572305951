/* MazeMap GUI: Route WayPoints A/B Info Container */
.mm-ui.route-waypoints{
    width: 100%;
    position: relative;

    &.style-searchinputs{
        background: #F8F8F8;
        padding-block: 10px;
        padding-inline: 0px;
        box-sizing: border-box;
    }

    .ui-options-container{ padding-block-end: 0px; padding-block-start: 0px; background: none;}
    .options-collapsable-toggle{ padding-inline-start: 40px !important; }
    .options-items{ padding-inline-start: 50px !important; }

    .btn-reverse {
        position: absolute;
        inset-block-start: 18px;
        inset-block-end: 18px;
        height: auto;
        inset-inline-start: 5px;
        width: 35px;
        border: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.mm-ui.route-waypoints .waypoint-item{
    position: relative;
    margin: 0;
    line-height: 28px;

    &.style-list{
        margin-block: 20px;
        margin-inline: 5px;
    }

    &.style-searchinputs{
        display: flex;
        margin-block-end: 7px;
        margin-block-start: 7px;
        margin-inline-end: 10px;
        margin-inline-start: 2px;
    }

    .waypoint-icon-desc{
        min-width: 42px;
        flex-grow: 0;
        font-size: 12px;
        color: #424445;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-input{
        /* Rectangle 6 Copy: */
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        border-radius: 4px;
        box-shadow: none;
        height: 28px;
        font-size: 13px;
        font-weight: bold;
    }
}

.mm-ui.route-waypoints .waypoint-item{

    input::-webkit-input-placeholder {
        color: #ff7a00;
        font-style: italic;
    }

    input:-moz-placeholder { /* Firefox 18- */
        color: #ff7a00;
        font-style: italic;
    }

    input::-moz-placeholder {  /* Firefox 19+ */
        color: #ff7a00;
        font-style: italic;
    }

    input:-ms-input-placeholder {
        color: #ff7a00;
        font-style: italic;
    }

}

.mm-ui.route-waypoints .waypoint-item .waypoint-title{
    display: block;
    margin-block-start: 0px;
    margin-inline-start: 47px;
    margin-inline-end: 20px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding: 0px;
    color: #424445;
    font-weight: 600;
    font-size: 14px;
}

.mm-ui.route-waypoints .waypoint-item .waypoint-detail{
    display: block;
    margin-inline-start: 47px;
    margin-inline-end: 20px;
    white-space: pre-wrap;
    padding: 0px;
    color: #676464;
    font-weight: normal;
    font-size: 13px;
}

.mm-ui.route-waypoints .waypoint-icon{
    position: absolute;
    inset-inline-start: 0px;
    margin-inline-start: 16px;
    inset-block-start: 0px;
    height: 20px;
    user-select: none;
}
