.mm-ui.booking-info-component{
    position: relative;

    .available-color{
        // background: rgb(254, 255, 253);
        // border: 1px solid rgb(234, 234, 234);
        color: #00c800;
    }
    .unavailable-color{
        // background: rgb(255, 253, 253);
        // border: 1px solid rgb(234, 234, 234);
        color: #FF5900;
    }

    h2, h3{
        margin-left: 39px;
    }

    h3{
        font-size: 14px;
        font-weight: normal;
        margin-top: 16px;
        margin-bottom: 0px;
        &.sub-icon-left-margin{
            margin-left: 25px;
        }
    }

    .book-slots-background {
        background: #f5f5f5;
        box-shadow: inset 1px 2px 5px 0px #00000040;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .book-container{
        display: flex;
        font-size: 12px;
        color: rgb(33, 33, 33);
        margin-left: 39px;
        margin-right: 25px;
        margin-bottom: 4px;
        margin-top: 12px;
        font-weight: normal;
        white-space: pre-wrap;
        flex-direction: column;
        align-items: stretch;
        white-space: normal;
    }

    .book-form-fields {
        padding-left: 2px;
    }

    .status-container{
        margin-bottom: 20px;
    }

    .info-block{
        margin-bottom: 10px;
        font-size: 14px;
    }

    .time-details-info{
        margin-top: 0px;
        font-size: 14px;
        display: inline-block;
    }

    a.calendar-link {

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #0099EA !important;
        text-decoration: none;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 10px;

        img {
            margin-right: 10px;
            max-width: 25px;
            max-height: 25px;
        }
    }
    a:hover{
        text-decoration: underline;
    }

    .booking-info-box-container{
        background: rgba(42, 229, 81, 0.15);
        box-sizing: border-box;
        margin: 20px;
        padding: 20px;
        border-radius: 6px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        background: rgba(255, 255, 255, 0.15);

        .booking-info-box{
            font-size: 18px;
            width: auto;
            padding: 20px 30px;
            margin: 0px;
        }

    }

    .requires-login {
        padding: 15px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    /* The animation code */
    @keyframes success-icon-animation{
      from {transform: rotateY(0deg);}
      to {transform: rotateY(360deg);}
    }

    .room-list-item {
        margin: 0px;
        border: 0;
    }
}
