.routing-feedback-modal-content {
    box-sizing: border-box;
    width: 100%;
    min-width: 275px;
    height: auto;
    z-index: 0;
    padding: 25px 25px 0px 25px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .routing-feedback-checkbox-container {
        margin: 5px;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
    }

    .routing-feedback-background-image {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 131px;
        height: 77px;
        z-index: -1;
    }

    .submit-routing-feedback-button {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
        font-size: 18px;
    }

}
