.external-report-modal-content {
  background: rgba(255, 255, 255, 0.15);
  box-sizing: border-box;
  margin: 0px;
  padding: 25px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  min-width: 275px;
  height: auto;
  z-index: 0;

  .external-report-modal-button {
    width: fit-content;
    margin: 25px 0px 10px 0px;
  }
}