.save-parking-modal-content {
    background: rgba(255, 255, 255, 0.15);
    box-sizing: border-box;
    margin: 0px;
    padding: 25px 25px 0px 25px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    min-width: 275px;
    height: auto;
    z-index: 0;

    .save-parking-modal-button {
        width: fit-content;
        width: 100px;
        min-width: 50px;

        .save-parking-modal-button-text {
            margin-right: 8px;
        }
    }

    .save-parking-modal-3-column-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 370px;
        width: 100%;
        margin: 20px auto 0px auto;
    }

    .save-parking-modal-marker-icon {
        display: none;
    }
}

/* If the screen is large enough, show the marker icon */
@media screen and (min-width: 340px) {
    .save-parking-modal-content {
        .save-parking-modal-marker-icon {
            display: inline-block;
        }
    }
}