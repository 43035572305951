.time-slot-element {
    background: #FFFDFD;
    border: 1px solid #DADADA;
    border-radius: 12px;
    // padding: 17px;
    display: block;
    padding: 4px 8px;
    margin: 5px 0px;
    width: auto;
    box-sizing: border-box;

    .info-block {
        margin: 10px;
        font-size: 12px;
    }

    span.date {
        font-weight: normal;
        display: block;
        font-size: 12px;
        margin-bottom: 7px;
        color: #464646;
        text-transform: capitalize;
    }

}

.time-span-row {
    font-size: 12px;

    span.time {
        font-weight: bold;
        white-space: nowrap;
        /* prevent "10.00 AM" to wrap*/
    }

    span.minutes {
        display: inline-block;
    }

    .time-line-seperator {
        margin-inline: 10px;
        color: #dadada;
    }

}
