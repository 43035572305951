.mmcmn-button {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold;
    padding: 7px 13px;
    box-sizing: border-box;

    text-rendering: geometricPrecision;
    -webkit-appearance: none;
    -moz-appearance: none;

    cursor: pointer;

    background: none;
    color: inherit;
    border: 0;
    outline: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .text-caps {
        text-transform: uppercase;
    }

    &:focus {
        outline: -webkit-focus-ring-color auto 5px;
        outline: -moz-focus-ring-color auto 5px;
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
}