.warning-message-component {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 60px;
    margin-right: 15px;
    clear: both;
    font-size: 14px;
    color: #2d2d2d;
}

.warning-message-component .icon {
    position: absolute;
    margin-left: -40px;
    width: 20px;
}