.mmcmn-navigation-mode-ui-root {
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mmwa-navigation-mode-map-ui-top-right-container {
    margin-block-start: 10px;
    margin-inline-end: 10px;
    background: none;
    pointer-events: all;

    .experimental-navigation-mode-exit-button {
        padding-inline-end: 20px;
        padding-inline-start: 20px;
    }
}
