@transition-duration: 0.5s;

.short-link-ui {
  input {
    position: relative;
  }

  .nomargin{
    margin-block: 0px;
  }

  .share-element-styling{
    width: 100%; font-family: monospace; font-size: 12px; background: #F7F7F7; box-sizing: border-box; padding: 10px; border-radius: 3px; border: 1px solid #D4D4D4 !important;
  }

  .error-msg{
    position: relative;
    color: #940A0A;
    background: #FDF0F0;

    button{
      display: inline-block;
      border: 0px;
      background: none;
      text-decoration: underline;
      margin-inline-start: 0px;
      padding: 0px;
      margin-block-start: 10px;
    }
  }
}
